export const DATE_FORMAT = "DD MMM YYYY";
export const DATE_TIME_FORMAT = "DD MM YYYY, HH:mm:ss";

export const ANDROID_LINK = "https://play.google.com/store/apps/details?id=com.medloop.v1&hl=en_GB";

export const IOS_LINK = "https://apps.apple.com/gb/app/medloop/id1459846548";

export const FAQ_LINK = "https://docs.google.com/document/d/1a0rgN6EFvK5OyAlecrifsCyaCivrudxuOcOQf6HOKGs/edit?ts=5fda24a3";

export const PRIVACY_NOTICE = "https://docs.google.com/document/d/1xGUU6mzFoXj_5PzKUJAZTCSkcOvBho3DGYBClxX4kzo/edit";

export const ACT_SCORE = "act_score";
export const AWARENESS = "asthma_self_awareness";
export const CONSULTATION = "consultation_title";
export const EXACERBATION = "exacerbation";
export const HEIGHT = "height";
export const ACTIVITIES = "limits_daily_activities";
export const SYMPTOMS = "night_symptoms";
export const SMOKING = "smoking_status";
export const BREATH = "shortness_of_breath";
export const WEIGHT = "weight";
export const RELIEVER = "use_of_reliever";
export const BMI = "BMI";
export const PEAK_FLOW = "peak_flow_rate";

export const CONTROLLED_PATIENT_STATUS = {
	IDENTIFIED: "IDENTIFIED",
	NOTIFIED: "NOTIFIED",
	ANSWERED: "ANSWERED",
	ACTION_REQUIRED: "ACTION_REQUIRED",
	PROCESSED: "PROCESSED",
	MANUAL_CONTACT: "MANUAL_CONTACT",
};

export const NOTIFICATION_ALLOWED_ON_PATIENT_STATUS = [CONTROLLED_PATIENT_STATUS.IDENTIFIED, CONTROLLED_PATIENT_STATUS.NOTIFIED];

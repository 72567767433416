import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {useSnackbar} from "notistack";
import {API, graphqlOperation, Auth} from "aws-amplify";

import {listSlotss, slotsByGp, slotsByStatus, locationByGpAndPostCode} from "../graphql/queries";
import {useJsonToCsv} from "react-json-csv";

import {sendCustomSMS} from "../graphql/customMutations";
import {updateSlots, deleteSlots} from "../graphql/mutations";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {CircularProgress, Backdrop} from "@material-ui/core";
import ListSubheader from "@material-ui/core/ListSubheader";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Checkbox from "@material-ui/core/Checkbox";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import SmsIcon from "@material-ui/icons/Sms";
import SlotFilter from "../components/SlotFilter";
import {getDateString} from "../utils/utils";
import moment from "moment";
import CakeIcon from "@material-ui/icons/Cake";
import PhoneIcon from "@material-ui/icons/Phone";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {getCSV, createBatchSlots} from "../utils/appointmentsActions";
import BatchAppointments from "../dialogs/BatchAppointments";
import CompleteAppointments from "../dialogs/CompleteAppointment";
import Dialog from "@material-ui/core/Dialog";
import ModifyBookings from "../components/ModifyBookings.js";
import SendMessageDialog from "../dialogs/SendMessageDialog";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
	// root: {
	// 	display: "flex",
	// 	flexWrap: "wrap",
	// },
	root: {
		width: "100%",
		maxWidth: "100%",
		backgroundColor: theme.palette.background.paper,
	},
	buttonGroup: {
		"& > *": {
			margin: theme.spacing(1),
		},
	},
	backdrop: {
		zIndex: 9999,
		color: "#000",
	},
	nested: {
		color: "green",
	},
	nestedBooked: {
		color: "blue",
	},
	nestedCancelled: {
		color: "red",
	},
	nestedAvailable: {
		color: "gray",
	},
	margin: {
		margin: theme.spacing(1),
	},
	withoutLabel: {
		marginTop: theme.spacing(3),
	},
	textField: {
		width: "25ch",
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	button: {
		margin: theme.spacing(1),
	},
	inline: {
		display: "flex",
		alignItems: "center",
	},
	paddingLeft: {
		paddingLeft: theme.spacing(),
		alignItems: "center",
		display: "flex",
	},
	details: {
		marginTop: theme.spacing(0.5),
		display: "flex",
	},
	details_icon: {
		display: "flex",
	},
	actionButton: {
		borderRadius: theme.spacing(1),
	},
	toolBarWrapper: {
		padding: theme.spacing(0, 4),
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(3),
	},
	toolBar: {
		display: "flex",
		alignItems: "center",
	},
	selectAll: {
		fontSize: 14,
		fontWeight: "500",
	},
	exportLink: {
		paddingLeft: theme.spacing(4.5),
		fontSize: 14,
		fontWeight: "500",
		cursor: "pointer",
		textTransform: "none",
	},
	list: {
		overflow: "auto",
		height: "100%",
		maxHeight: "calc(100vh - 264px)",
	},
	subHeader: {
		backgroundColor: "white",
	},
	today: {
		borderRadius: theme.spacing(0.5),
		height: theme.spacing(4.25),
		marginLeft: theme.spacing(1.25),
	},
}));

export default function InputAdornments() {
	const classes = useStyles();
	const [slots, setSlots] = useState([]); //TODO rename this, may keys, groupedSlots..

	const [user, setUser] = useState(null);
	const [isLoading, setIsLoading] = useState([]);
	const {enqueueSnackbar} = useSnackbar();

	const [currentDate, setCurrentDate] = React.useState(null);
	const [gpLocation, setGpLocation] = React.useState(null);
	const [status, setStatus] = React.useState(null);
	const [open, setOpen] = React.useState(true);
	const [allSelected, setAllSelected] = useState(false);
	const [anySelected, setAnySelected] = useState(false);
	const [batchCreateOpen, setBatchCreateOpen] = useState(false);
	const [completeAppointmentsOpen, setCompleteAppointmentsOpen] = useState(false);
	const [completeAppointmentOpen, setCompleteAppointmentOpen] = useState(false);
	const [toCompleteSlot, setToCompleteSlot] = useState([]);
	const [modifyPatientGuid, setModifyPatientGuid] = useState({patient_guid: "", patient_hash: "", reason: "", patient_title: ""});
	const [modifyOpen, setModifyOpen] = useState(false);
	const [notifyDialogOpen, setNotifyDialogOpen] = useState(false);
	const [toNotifySlot, setToNotifySlot] = useState(null);
	const [isSelectAllDisabled, setIsSelectAllDisabled] = useState(false);

	let history = useHistory();
	const handleClick = key => {
		slots[key].open = !slots[key].open;
		setSlots({...slots});
	};

	React.useEffect(() => {
		if (!user || !currentDate) return;
		const getSlots = async () => {
			console.log("get slots", currentDate);
			try {
				setIsLoading(true);
				let d = currentDate;
				let year = d.getFullYear();
				var month = ("0" + (d.getMonth() + 1)).slice(-2);
				var day = ("0" + d.getDate()).slice(-2);
				let filter = {
					...(gpLocation && {staff_id: {eq: gpLocation.staff_id}}),
					...(status && {status: {eq: status}}),
				};
				if (Object.keys(filter).length === 0 && filter.constructor === Object) {
					filter = null;
				}
				const result = await API.graphql(
					graphqlOperation(slotsByGp, {
						gp_guid: user.attributes["custom:gp_guid"],
						yearMonthDayHourMinuteStaff_id: {
							beginsWith: {
								year: year,
								month: month,
								day: day,
							},
						},
						...(filter && {filter: filter}),
						limit: 1000,
					})
				);
				//let _slots = result.data.slotsByGp.items.filter(slot => slot.status !== "AVAILABLE");
				let _slots = result.data.slotsByGp.items;

				_slots = _slots.reduce((r, e) => {
					//TODO adjust to do by location, maybe the query should do by location.
					let key = `${e.year}#${e.month}#${e.day}#${e.hour}#${e.minute}#${e.location.staff_id}`;
					e.selected = false;
					try {
						e.reason = JSON.parse(e.reason);
					} catch (e) {
						//
					}
					if (!r[key]) {
						let start = new Date(e.year, e.month - 1, e.day, e.hour, e.minute);
						let end = new Date(start.getTime() + e.duration * 60000);
						let endString = `${("0" + end.getHours()).slice(-2)}:${("0" + end.getMinutes()).slice(-2)}`;
						r[key] = {
							date: `${e.day}/${e.month}/${e.year}`,
							start: `${e.hour}:${e.minute}`,
							end: endString,
							open: true,
							location: e.location,
							slots: [e], //TODO maybe rename this
						};
					} else {
						if (e.status === "BOOKED") {
							r[key].open = true;
						}
						r[key].slots.push(e);
					}
					return r;
				}, {});
				if (Object.keys(_slots).length === 0 && _slots.constructor === Object) {
					console.log("empty");
					//setSlotKeys([]);
					setSlots([]);
				} else {
					console.table(Object.values(_slots));
					//setSlotKeys(Object.keys(_slots));
					setSlots(_slots);
				}
			} catch (error) {
				console.log(error);
				enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
			} finally {
				setIsLoading(false);
			}
		};
		getSlots();
	}, [currentDate, enqueueSnackbar, user, gpLocation, status]);

	React.useEffect(() => {
		if (user) return;
		const getUser = async () => {
			try {
				setIsLoading(true);
				const _user = await Auth.currentAuthenticatedUser();
				setUser(_user);
				setCurrentDate(new Date());
			} catch (error) {
				console.log(error);
				enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
			} finally {
				setIsLoading(false);
			}
		};
		getUser();
	}, [enqueueSnackbar, user]);

	const nextDay = () => {
		let nextDay = currentDate;
		nextDay.setDate(nextDay.getDate() + 1);
		setCurrentDate(new Date(nextDay.getTime()));
		console.log(currentDate);
	};

	const prevDay = () => {
		let prevDay = currentDate;
		prevDay.setDate(prevDay.getDate() - 1);
		setCurrentDate(new Date(prevDay.getTime()));
		console.log(currentDate);
	};

	const setToday = () => {
		setCurrentDate(new Date());
	};

	const sendNotif = async (slot, values) => {
		console.log(slot);
		console.log(slot.reason);
		console.log(values);

		// push to SQS instead
		try {
			await API.graphql(
				graphqlOperation(sendCustomSMS, {
					input: JSON.stringify({
						patientGuid: slot.patient_guid,
						sourceId: slot.reason.source_id,
						type: "CUSTOM",
						content: values.message,
					}),
				})
			);
			enqueueSnackbar("SMS sucessfully sent.", {variant: "success"});
		} catch (err) {
			console.log(`ERROR: ${JSON.stringify(err)}`);
			enqueueSnackbar("Error sending SMS", {variant: "error"});
		}
	};

	const updateStatus = async ({slot, from_status, to_status}) => {
		try {
			const initial_status = slot.status;
			slot.status = to_status;
			slot.staff_id = slot.location.staff_id;
			delete slot.location;
			const res = await API.graphql(
				graphqlOperation(updateSlots, {
					input: slot,
					...(from_status && {condition: {status: {eq: from_status}}}),
				})
			);
			if (initial_status === "BOOKED" && to_status === "CANCELLED") {
				let values = {
					message: `Hello ${slot.reason?.patient_name}, we're sorry to inform that your covid vaccination appointment on: ${slot.day}/${slot.month}/${slot.year} got cancelled, please contact us on: xxxx for more information.`,
				};
				sendNotif(slot, values, "CANCELLED");
			}

			return res;
		} catch (error) {
			enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
			console.log(error);
		}
	};

	const getSelectedSlots = toStatus => {
		let selected = Object.keys(slots).reduce((acc, key) => {
			let selectedSlots = slots[key].slots.filter(e => e.selected && status === "BOOKED");
			acc = [...acc, ...selectedSlots];
			return acc;
		}, []);
		return selected;
	};

	const handleBatchCreateClose = () => setBatchCreateOpen(false);

	const getToCompleteSlot = () => {
		return toCompleteSlot;
	};

	const handleModifyClose = () => {
		setModifyOpen(false);
		setModifyPatientGuid(null);
	};

	const completeSlot = slot => {
		setToCompleteSlot([slot]);
		setCompleteAppointmentOpen(true);
	};

	const openNotifyDialog = slot => {
		setToNotifySlot(slot);
		setNotifyDialogOpen(true);
	};

	const bulkCancel = async () => {
		setIsLoading(true);
		const toBeCancelled = getSelectedSlots();
		const toBeDeleted = getSelectedSlotsToDelete();
		//refactor this to batch
		try {
			for (let i = 0; i < toBeCancelled.length; i++) {
				delete toBeCancelled[i].selected;
				await updateStatus({slot: toBeCancelled[i], from_status: toBeCancelled[i].status, to_status: "CANCELLED"});
			}
			for (let i = 0; i < toBeDeleted.length; i++) {
				delete toBeDeleted[i].selected;
				await deleteWithoutFeedback(toBeDeleted[i]);
			}
			setCurrentDate(new Date(currentDate.getTime()));
			enqueueSnackbar("Slots successfully cancelled", {variant: "success"});
		} catch (e) {
			console.log(e);
			enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
		} finally {
			setIsLoading(false);
		}
	};

	const bulkComplete = async () => {
		setIsLoading(true);
		const toBeCompleted = getSelectedSlots();
		try {
			for (let i = 0; i < toBeCompleted.length; i++) {
				delete toBeCompleted[i].selected;
				await updateStatus({slot: toBeCompleted[i], from_status: toBeCompleted[i].status, to_status: "COMPLETED"});
			}
			setCurrentDate(new Date(currentDate.getTime()));
			enqueueSnackbar("Slots successfully cancelled", {variant: "success"});
		} catch (error) {
			console.log(error);
			enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
		} finally {
			setIsLoading(false);
		}
	};

	const cancel = async slot => {
		setIsLoading(true);
		try {
			delete slot.selected;
			await updateStatus({slot: slot, from_status: slot.status, to_status: "CANCELLED"});

			setCurrentDate(new Date(currentDate.getTime()));
			enqueueSnackbar("Slot successfully cancelled", {variant: "success"});
		} catch (e) {
			console.log(e);
			enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
		} finally {
			setIsLoading(false);
		}
	};

	const deleteWithoutFeedback = async _slot => {
		try {
			//TODO: call api to delete the slot
			const res = await API.graphql(
				graphqlOperation(deleteSlots, {
					input: {
						id: _slot.id,
					},
				})
			);
		} catch (error) {
			console.log(error);
			enqueueSnackbar("Failed to delete slot.", {variant: "error"});
		}
	};

	const deleteEvent = async _slot => {
		try {
			await deleteWithoutFeedback(_slot);
			setCurrentDate(new Date(currentDate.getTime()));
			enqueueSnackbar("Slot deleted successfully.", {variant: "success"});
		} catch (error) {
			console.log(error);
			enqueueSnackbar("Failed to delete slot.", {variant: "error"});
		}
	};

	const handleToggle = (key, id) => {
		console.log(key, id);
		let toggled = slots[key].slots.find(e => e.status !== "COMPLETED" && e.status !== "CANCELLED" && e.id === id);
		toggled.selected = !toggled.selected;
		setSlots({...slots});
	};

	const toggleSelectAll = () => {
		let _slots = slots;
		Object.keys(_slots).map(key => {
			_slots[key].slots.map(slot => {
				if (slot.status !== "COMPLETED" && slot.status !== "CANCELLED") {
					slot.selected = !allSelected;
				}
			});
		});
		setSlots({..._slots});
		setAllSelected(!allSelected);
	};

	const handleNotifyDialogClose = () => setNotifyDialogOpen(false);

	const getSelectedSlotsToDelete = () => {
		let selected = Object.keys(slots).reduce((acc, key) => {
			let selectedSlots = slots[key].slots.filter(e => e.selected && e.status === "AVAILABLE");
			acc = [...acc, ...selectedSlots];
			return acc;
		}, []);

		return selected;
	};

	const getSelectedSlotsToComplete = () => {
		let selected = Object.keys(slots).reduce((acc, key) => {
			let selectedSlots = slots[key].slots.filter(e => e.selected && e.status === "BOOKED");
			acc = [...acc, ...selectedSlots];
			return acc;
		}, []);

		return selected;
	};

	useEffect(() => {
		let any = true;
		let _slots = slots;
		let count = 0;
		Object.keys(_slots).map(key => {
			_slots[key].slots.map(slot => {
				if (slot.status !== "COMPLETED" && slot.status !== "CANCELLED") {
					count++;
					if (!slot.selected) {
						any = false;
					}
				}
			});
		});
		if (count < 1) {
			any = false;
		}
		setIsSelectAllDisabled(count < 1);
		setAllSelected(any);
	}, [slots]);

	useEffect(() => {
		let any = false;
		let _slots = slots;
		Object.keys(_slots).map(key => {
			_slots[key].slots.map(slot => {
				if (slot.selected) {
					any = true;
				}
			});
		});
		setAnySelected(any);
	}, [slots]);

	const openModifyDialog = slot => {
		setModifyPatientGuid({
			patient_guid: slot.patient_guid,
			patient_hash: slot.patient_hash,
			reason: slot?.reason,
			patient_title: slot?.title,
		});
		setModifyOpen(true);
	};

	return (
		<div className={classes.root}>
			<SlotFilter setGpLocation={setGpLocation} setStatus={setStatus} />
			<Grid container className={classes.toolBarWrapper} justify="space-between">
				<Grid className={classes.toolBar} color="secondary">
					<IconButton onClick={prevDay}>
						<ArrowBackIosIcon fontSize="small" />
					</IconButton>
					<IconButton onClick={nextDay}>
						<ArrowForwardIosIcon fontSize="small" />
					</IconButton>
					<Button size="small" onClick={setToday} variant="outlined" className={classes.today}>
						Today
					</Button>
				</Grid>
				<Grid className={classes.toolBar}>
					<Typography variant="h6">{moment(currentDate).format("ddd, DD.MM.YY")}</Typography>
				</Grid>
				<Grid className={classes.toolBar}>
					<ButtonGroup size="small" color="secondary" aria-label="text primary button group">
						<Button onClick={() => setBatchCreateOpen(true)}>Create Appointment Series</Button>
					</ButtonGroup>
					<ButtonGroup size="small" color="secondary" aria-label="text primary button group">
						<Button className={classes.today} onClick={() => getCSV(user, setIsLoading, enqueueSnackbar)}>
							Export Appointment Data
						</Button>
					</ButtonGroup>
					<ButtonGroup color="secondary" aria-label="outlined primary button group">
						<Button variant="outlined" className={classes.today} disabled>
							Day
						</Button>
						<Button
							variant="outlined"
							className={classes.today}
							onClick={() => history.push({pathname: `/dashboard/appointments`, state: {cal: "timeGridWeek"}})}
						>
							week
						</Button>
						<Button
							variant="outlined"
							className={classes.today}
							onClick={() => history.push({pathname: `/dashboard/appointments`, state: {cal: "dayGridMonth"}})}
						>
							month
						</Button>
					</ButtonGroup>
				</Grid>
			</Grid>
			<Grid container justify="center">
				<Grid item md={8}>
					<List
						className={classes.list}
						subheader={
							<ListSubheader component="div" id="nested-list-subheader" className={classes.subHeader}>
								{/* My Appointments: {currentDate?.toISOString()}
								<ButtonGroup variant="text" color="primary" aria-label="text primary button group">
									<Button onClick={prevDay}>Prev</Button>
									<Button onClick={() => nextDay()}>Next</Button>
									<Button onClick={() => bulkCancel()}>Batch Cancel</Button>
								</ButtonGroup> */}
								<Grid container direction="row" justify="space-between" alignItems="center">
									<Grid item className={classes.toolBar}>
										<Checkbox
											edge="start"
											tabIndex={-1}
											onChange={toggleSelectAll}
											checked={allSelected}
											disabled={isSelectAllDisabled}
											indeterminate={!allSelected && anySelected}
										/>
										<Typography className={classes.selectAll}>Select All</Typography>
										{/* <Typography className={classes.exportLink}>
											<Link color="secondary" disabled={true} onClick={getCSV}>
												Export Pinnacle CSV
											</Link>
										</Typography> */}
										<Button size="small" disabled className={classes.exportLink} onClick={() => alert("Not Implemented")}>
											Export Pinnacle CSV
										</Button>
									</Grid>
									<Grid item className={classes.buttonGroup}>
										<Button
											onClick={bulkCancel}
											disableElevation
											disabled={!anySelected && !allSelected}
											variant="contained"
											color="rgba(46, 54, 90, 0.08)"
											className={classes.actionButton}
										>
											Cancel Selected
										</Button>
										<Button
											onClick={() => setCompleteAppointmentsOpen(true)}
											disableElevation
											disabled={moment().diff(moment(currentDate)) < 0 || (!anySelected && !allSelected)}
											variant="contained"
											color="primary"
											className={classes.actionButton}
										>
											Complete Selected
										</Button>
									</Grid>
								</Grid>
							</ListSubheader>
						}
					>
						{Object.keys(slots).map(key => (
							<React.Fragment key={key}>
								<Divider />
								<ListItem key={key} onClick={() => handleClick(key)}>
									<ListItemIcon>
										{/* <AccessTimeIcon /> */}
										<Typography className={classes.group} color="textPrimary">
											{getDateString(currentDate, ".")}
										</Typography>
									</ListItemIcon>

									<ListItemSecondaryAction className={classes.inline} onClick={() => handleClick(key)}>
										<Typography variant="h6" color="textPrimary">
											{slots[key].start} - {slots[key].end}
										</Typography>
										<Grid item className={classes.paddingLeft} alignItems={"center"}>
											{slots[key].open ? <ExpandLess ml={5} /> : <ExpandMore ml={5} />}
										</Grid>
									</ListItemSecondaryAction>
								</ListItem>
								<Collapse in={slots[key].open} timeout="auto">
									{slots[key].slots.map(slot => (
										<List key={slot.id} component="div" disablePadding>
											<ListItem
												button
												disabled={slot.status === "COMPLETED" || slot.status === "CANCELLED"}
												onClick={() => handleToggle(key, slot.id)}
											>
												<ListItemIcon>
													<Checkbox
														disabled={slot.status === "COMPLETED" || slot.status === "CANCELLED"}
														edge="start"
														checked={slot.selected}
														tabIndex={-1}
														inputProps={{"aria-labelledby": slot.id}}
													/>
												</ListItemIcon>
												<ListItemText
													primary={
														<React.Fragment>
															<Typography component="span" variant="body2" color="textPrimary">
																{slot.status === "BOOKED" || slot.status === "COMPLETED"
																	? `${slot.reason.patient_surname}, ${slot.reason.patient_name}`
																	: slot.status}
															</Typography>
															{slot.status === "BOOKED" || slot.status === "COMPLETED" ? (
																<React.Fragment>
																	<br />
																	<Typography
																		component="span"
																		variant="body2"
																		className={
																			slot.status === "BOOKED"
																				? classes.nestedBooked
																				: slot.status === "CANCELLED"
																				? classes.nestedCancelled
																				: slot.status === "AVAILABLE"
																				? classes.nestedAvailable
																				: classes.nested
																		}
																		color="textPrimary"
																	>
																		{slot.status}
																	</Typography>
																</React.Fragment>
															) : (
																""
															)}
														</React.Fragment>
													}
													secondary={
														<React.Fragment>
															{/* <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
																{slot.status === "BOOKED" || slot.status === "COMPLETED"
																	? `${slot.reason.patient_birthdate} | ${slot.reason.patient_mobile} | ${slot.location?.name}`
																	: `${slot.location?.name}`}
															</Typography> */}
															{slot.status === "BOOKED" || slot.status === "COMPLETED" ? (
																<Grid container alignItems="center" spacing={1} className={classes.details}>
																	{slot.reason?.patient_birthdate ? (
																		<React.Fragment>
																			<Grid item>
																				<CakeIcon className={classes.details_icon} fontSize={"small"} />
																			</Grid>
																			<Grid item>
																				<Typography component="span" variant="body2" color="textPrimary">
																					{slot.reason.patient_birthdate}
																				</Typography>
																			</Grid>
																		</React.Fragment>
																	) : (
																		""
																	)}
																	{slot.reason?.patient_mobile ? (
																		<React.Fragment>
																			<Grid item>
																				<PhoneIcon className={classes.details_icon} fontSize={"small"} />
																			</Grid>
																			<Grid item>
																				<Typography component="span" variant="body2" color="textPrimary">
																					{slot.reason.patient_mobile}
																				</Typography>
																			</Grid>
																		</React.Fragment>
																	) : (
																		""
																	)}
																	<Grid item alignItems="center">
																		<LocalHospitalIcon className={classes.details_icon} fontSize={"small"} />
																	</Grid>
																	<Grid item>
																		<Typography component="span" variant="body2" color="textPrimary">
																			{slot.location?.name}
																		</Typography>
																	</Grid>
																	{slot.reason?.patient_nhs_number ? (
																		<React.Fragment>
																			<Grid item alignItems="center">
																				<FingerprintIcon className={classes.details_icon} fontSize={"small"} />
																			</Grid>
																			<Grid item>
																				<Typography component="span" variant="body2" color="textPrimary">
																					{slot.reason?.patient_nhs_number}
																				</Typography>
																			</Grid>
																		</React.Fragment>
																	) : (
																		""
																	)}
																</Grid>
															) : (
																<Grid container alignItems="center" spacing={1} className={classes.details}>
																	<Grid item>
																		<LocalHospitalIcon className={classes.details_icon} fontSize={"small"} />
																	</Grid>
																	<Grid item>
																		<Typography component="span" variant="body2" color="textPrimary">
																			{slot.location?.name}
																		</Typography>
																	</Grid>
																</Grid>
															)}
														</React.Fragment>
													}
												/>
												<ListItemSecondaryAction>
													<div className={classes.buttonGroup}>
														<IconButton
															disabled={slot.status === "CANCELLED" || slot.status === "AVAILABLE"}
															onClick={() => openNotifyDialog(slot)}
														>
															<SmsIcon></SmsIcon>
														</IconButton>
														{slot.status === "AVAILABLE" ? (
															<Button
																onClick={() => deleteEvent(slot)}
																variant="contained"
																disableElevation
																color="rgba(46, 54, 90, 0.08)"
																className={classes.actionButton}
																sizeLarge
															>
																Delete
															</Button>
														) : (
															<Button
																onClick={() => cancel(slot)}
																disabled={slot.status === "CANCELLED" || slot.status === "COMPLETED"}
																disableElevation
																variant="contained"
																color="rgba(46, 54, 90, 0.08)"
																className={classes.actionButton}
															>
																Cancel
															</Button>
														)}
														{slot.status === "BOOKED" &&
														moment().diff(moment(new Date(slot.year, slot.month - 1, slot.day, slot.hour, slot.minute))) < 0 ? (
															<Button
																disabled={slot.status !== "BOOKED"}
																onClick={() => openModifyDialog(slot)}
																disableElevation
																variant="contained"
																color="primary"
																className={classes.actionButton}
															>
																Modify
															</Button>
														) : (
															<Button
																disabled={slot.status !== "BOOKED"}
																onClick={() => completeSlot(slot)}
																variant="contained"
																disableElevation
																className={classes.actionButton}
																sizeLarge
																color="primary"
															>
																Complete
															</Button>
														)}
													</div>
												</ListItemSecondaryAction>
											</ListItem>
										</List>
									))}
								</Collapse>
							</React.Fragment>
						))}
					</List>
				</Grid>
			</Grid>
			<BatchAppointments
				isOpen={batchCreateOpen}
				setIsOpen={setBatchCreateOpen}
				setLoading={setIsLoading}
				callback={() => setCurrentDate(new Date(currentDate.getTime()))}
			/>
			<CompleteAppointments
				isOpen={completeAppointmentsOpen}
				setIsOpen={setCompleteAppointmentsOpen}
				setLoading={setIsLoading}
				getSlots={getSelectedSlotsToComplete}
				callback={() => setCurrentDate(new Date(currentDate.getTime()))}
			/>
			<CompleteAppointments
				isOpen={completeAppointmentOpen}
				setIsOpen={setCompleteAppointmentOpen}
				setLoading={setIsLoading}
				getSlots={getToCompleteSlot}
				callback={() => setCurrentDate(new Date(currentDate.getTime()))}
			/>
			<Dialog open={modifyOpen} onClose={handleModifyClose} aria-labelledby="complete-dialog">
				<ModifyBookings
					patient_ids={modifyPatientGuid}
					user={user}
					handleClose={handleModifyClose}
					setLoadingBackdrop={setIsLoading}
					refreshEvents={() => setCurrentDate(new Date(currentDate.getTime()))}
				/>
			</Dialog>
			<SendMessageDialog slot={toNotifySlot} handleClose={handleNotifyDialogClose} isOpen={notifyDialogOpen} />
			<Backdrop className={classes.backdrop} open={isLoading}>
				<CircularProgress color="primary" />
			</Backdrop>
		</div>
	);
}

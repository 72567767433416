import {AmplifyAuthenticator, AmplifySignIn, AmplifySignUp} from "@aws-amplify/ui-react";
import React from "react";
import "../App.css";

export default function AmplifyLogin() {
	const formFields = [
		{
			type: "email",
			placeholder: "Email",
			required: true,
			label: "",
		},
		{
			type: "password",
			required: true,
			placeholder: "password",
			label: "",
		},
		{
			type: "custom:ods_code",
			label: "Surgery Code",
			placeholder: "Enter the ODS Code of your organisation",
			required: true,
		},
		{
			type: "custom:api_key",
			label: "API KEY",
			placeholder: "Enter your Medloop API KEY",
			required: true,
		},
		{
			type: "custom:emis_login",
			label: "Emis Login",
			placeholder: "Enter your Emis WEB Client login name",
			required: false,
		},
	];

	const signInFields = [
		{
			type: "email",
			placeholder: "Email",
			required: true,
			label: "",
		},
		{
			type: "password",
			placeholder: "password",
			required: true,
			label: "",
		},
	];

	return (
		<React.Fragment>
			<AmplifyAuthenticator usernameAlias="email">
				<AmplifySignUp slot="sign-up" usernameAlias="email" formFields={formFields} />
				<AmplifySignIn formFields={signInFields} slot="sign-in" headerText="GP Login" usernameAlias="email" />
			</AmplifyAuthenticator>
		</React.Fragment>
	);
}

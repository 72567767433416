import React from "react";
import {Route, HashRouter, Switch, Redirect} from "react-router-dom";
import {ThemeProvider} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {SnackbarProvider} from "notistack";

import {theme} from "./utils/theme.js";
import SurveyPage from "./pages/Survey.js";
import PatientCancellationPage from "./pages/PatientCancellation.js";
import Dashboard from "./dashboard";

const useStyles = makeStyles(theme => ({
	wrapper: {
		// padding: theme.spacing(3),
		height: "100%",
		// paddingTop: theme.spacing(4),
	},
}));

const AuthStateApp = () => {
	const classes = useStyles();
	return (
		<ThemeProvider theme={theme}>
			<div className="App">
				<SnackbarProvider
					maxSnack={3}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>
					<HashRouter>
						<Switch>
							<Box className={classes.wrapper}>
								<Route exact path="/survey/:hash" component={SurveyPage} />
								<Route exact path="/s/:hash" component={SurveyPage} />
								<Route exact path="/slots/:hash" component={PatientCancellationPage} />
								<Route path="/dashboard" component={Dashboard} />
								<Route exact path="/">
									<Redirect to="/dashboard" />
								</Route>
							</Box>
						</Switch>
					</HashRouter>
				</SnackbarProvider>
			</div>
		</ThemeProvider>
	);
};

export default AuthStateApp;

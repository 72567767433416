import React from "react";
import {useLocation} from "react-router-dom";

import clsx from "clsx";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";

import {AmplifySignOut} from "@aws-amplify/ui-react";

import PublishIcon from "@material-ui/icons/Publish";
import ListIcon from "@material-ui/icons/List";
import medloopLogo from "../icon-general-medlooplogo.svg";
import DateRangeIcon from "@material-ui/icons/DateRange";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
	},
	grow: {
		flexGrow: 1,
	},
	appBar: {
		backgroundColor: "#ffffff",
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		color: "#000000",
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	hide: {
		display: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9) + 1,
		},
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),

		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	sectionDesktop: {
		display: "none",
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
		alignItems: "center",
	},
	practiceName: {
		fontSize: 24,
		marginRight: theme.spacing(1.5),
		fontWeight: "500",
		color: theme.palette.secondary.dark,
	},
}));

export default function MiniDrawer() {
	const location = useLocation();
	const classes = useStyles();
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const isMenuOpen = Boolean(anchorEl);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleProfileMenuOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const menuId = "primary-search-account-menu";
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{vertical: "top", horizontal: "right"}}
			id={menuId}
			keepMounted
			transformOrigin={{vertical: "top", horizontal: "right"}}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<AmplifySignOut
				style={{
					"--amplify-primary-color": "transparent",
					"--amplify-primary-tint": "transparent",
					"--amplify-primary-shade": "transparent",
					"--amplify-primary-contrast": "black",
				}}
			/>
		</Menu>
	);

	return (
		<React.Fragment>
			<CssBaseline />
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
			>
				<Toolbar>
					<IconButton
						// color="#000000"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						className={clsx(classes.menuButton, {
							[classes.hide]: open,
						})}
					>
						<MenuIcon />
					</IconButton>
					<img alt="medloop-logo" src={medloopLogo}></img>
					<div className={classes.grow} />
					<div className={classes.sectionDesktop}>
						<Typography className={classes.practiceName}>GP Practice name</Typography>
						<IconButton
							edge="end"
							aria-label="account of current user"
							aria-controls={menuId}
							aria-haspopup="true"
							onClick={handleProfileMenuOpen}
							// color="#000000"
						>
							<AccountCircle />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>
			{renderMenu}
			<Drawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
			>
				<div className={classes.toolbar}>
					<IconButton onClick={handleDrawerClose}>{theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
				</div>
				<Divider />
				<List>
					<ListItem selected={location.pathname === "/dashboard"} button component="a" href="#/dashboard">
						<ListItemIcon>
							<ListIcon color={location.pathname === "/dashboard" ? "primary" : "inherit"} />
						</ListItemIcon>
						<ListItemText primary="Patient Lists" />
					</ListItem>
					{/* <ListItem button component="a" href="#/addsource">
						<ListItemIcon>
							<LocalHospitalIcon />
						</ListItemIcon>
						<ListItemText primary="Add Source" />
					</ListItem> */}
					<ListItem button selected={location.pathname === "/dashboard/upload"} component="a" href="#/dashboard/upload">
						<ListItemIcon>
							<PublishIcon color={location.pathname === "/dashboard/upload" ? "primary" : "inherit"} />
						</ListItemIcon>
						<ListItemText primary="Upload File" />
					</ListItem>
					{/* <ListItem button component="a" href="#/dashboard/slots">
						<ListItemIcon>
							<PlaylistAddIcon />
						</ListItemIcon>
						<ListItemText primary="Create Slots" />
					</ListItem>
					<ListItem button component="a" href="#/dashboard/myslots">
						<ListItemIcon>
							<PlaylistAddIcon />
						</ListItemIcon>
						<ListItemText primary="My Slots" />
					</ListItem> */}
					<ListItem button selected={location.pathname === "/dashboard/appointments"} component="a" href="#/dashboard/appointments">
						<ListItemIcon>
							<DateRangeIcon color={location.pathname === "/dashboard/appointments" ? "primary" : "inherit"} />
						</ListItemIcon>
						<ListItemText primary="Appointments" />
					</ListItem>
				</List>
				<Divider />
			</Drawer>
		</React.Fragment>
	);
}

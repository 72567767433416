import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import Amplify, {Auth} from "aws-amplify";
import awsExports from "./aws-exports";

let App;

Amplify.configure(awsExports);

App = require("./App.js").default;
Amplify.configure({
	API: {
		graphql_headers: async () => {
			const session = await Auth.currentSession();
			return {
				Authorization: session.getIdToken().getJwtToken(),
			};
		},
	},
});

ReactDOM.render(<React.StrictMode>{<App />}</React.StrictMode>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import {Box, Button, CircularProgress, Grid, Link, Slide, TextareaAutosize, Typography} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import LaptopIcon from "@material-ui/icons/Laptop";
import LockIcon from "@material-ui/icons/Lock";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import {API} from "aws-amplify";
import React, {useEffect, useState} from "react";
import Badge from "react-download-android";
import BadgeIOS from "react-download-ios";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import AppointmentBooking from "../components/AppointmentBooking.js";
import questionaryImg from "../Questionaire.png";
import {ANDROID_LINK, FAQ_LINK, IOS_LINK, PRIVACY_NOTICE} from "../utils/constants";
import ICONS from "../utils/icons";
import withWidth, {isWidthUp} from "@material-ui/core/withWidth";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

Survey.StylesManager.applyTheme("modern");

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
		backgroundColor: theme.palette.background.paper,
	},
	inline: {
		display: "inline",
	},
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	feedbackWrap: {
		backgroundColor: "rgb(244, 244, 244)",
		margin: theme.spacing(1) + 2,
		borderRadius: 4,
		padding: theme.spacing(3),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		minHeight: theme.spacing(52),
	},
	success: {
		fontSize: 96,
		color: "rgb(138 138 138)",
	},
	feedback: {
		marginBottom: theme.spacing(1),
		width: "94%",
		borderRadius: 6,
		borderColor: theme.palette.primary.light,
		padding: theme.spacing(1),
		fontSize: 16,
		borderWidth: 2,
	},
	thankyou: {
		fontSize: 28,
		fontWeight: 600,
		color: "rgb(138 138 138)",
	},
	feedbackContainer: {
		marginTop: theme.spacing(3),
		minHeight: theme.spacing(33),
		marginBottom: theme.spacing(3),
		display: "flex",

		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end",
	},
	feedbackSubmitted: {
		marginTop: theme.spacing(3),
		minHeight: theme.spacing(33),
		marginBottom: theme.spacing(3),
		display: "flex",

		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
	feedbackSent: {
		fontSize: 32,
		marginLeft: theme.spacing(2),
	},
	submitButton: {
		color: "#fff",
		backgroundColor: theme.palette.secondary.dark,
	},
	link: {
		color: theme.palette.primary.dark,
	},
	info: {
		color: "rgb(138 138 138)",
		fontSize: 18,
	},
	hero: {
		background: "#1dcabf",
		padding: theme.spacing(3),
		borderRadius: theme.spacing(0.5),
		boxShadow: "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)",
	},
	loaderWrap: {
		height: "100vh",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
	imgWrap: {
		textAlign: "center",
	},
	startButton: {
		fontSize: "20px",
		textTransform: "none",
		color: "white",
		maxWidth: "320px",
		minWidth: "320px",
	},
	bookingWrapper: {
		display: "flex",
		flexDirection: "column",
	},
	bookingRow: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		padding: theme.spacing(1.5, 0),
	},
	slots: {
		flex: 1,
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
	},
	headingAcc: {
		display: "block",
		fontSize: 22,
		fontWeight: theme.typography.fontWeightRegular,
	},
	sectionHeader: {
		fontSize: 24,
		color: "rgba(0, 0, 0, 0.87)",
	},
}));

const myCss = {
	header: "my-title sv-title sv-container-modern__title",
};

const SurveyPage = props => {
	const classes = useStyles();
	const [model, setModel] = useState(null);
	const [gpName, setGpName] = useState(null);
	const [surveyName, setSurveyName] = useState(null);
	const [dob, setDob] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [surveyValidation, setSurveyValidation] = useState(false);
	const [started, setStarted] = React.useState(false);
	const [checked, setChecked] = React.useState(true);
	const [isCompleted, setIsCompleted] = useState(false);
	const [showAppointments, setShowAppointments] = useState(false);
	const [response, setResponse] = useState(null);
	const [responseOptions, setResponseOptions] = useState(null);
	const [locations, setLocations] = useState(null);
	const [bookedSlots, setBookedSlots] = useState([]);

	const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
	const {
		match: {params},
	} = props;

	const surveyValidatePatientDetails = React.useCallback(
		async (survey, options) => {
			//options.data contains the data for the current page.
			const dob = options.data["dob"];
			if (!dob) {
				options.complete();
				return;
			}
			const requestParams = {
				body: {
					birthdate: dob,
				},
			};

			try {
				setSurveyValidation(true);
				await API.post("surveys", `/surveys/${params.hash}/validate`, requestParams);
				setDob(dob);
			} catch (error) {
				console.log(error);
				options.errors["dob"] = "Please, inform the Date of Birth used to register in your GP.";
			}
			setSurveyValidation(false);
			options.complete();
		},
		[params]
	);

	useEffect(() => {
		setLoading(true);
		setModel(null);
		const getSurvey = async () => {
			try {
				let response = await API.get("surveys", `/surveys/${params.hash}`);
				let survey = new Survey.Model(response.survey.content); //should get the survey via the API response.
				survey.onServerValidateQuestions.add(surveyValidatePatientDetails);
				console.log(response);
				setModel(survey);
				setSurveyName(response.survey.name);
				setGpName(response.gp_name);
				setIsCompleted(false);
				setIsFeedbackSubmitted(false);
			} catch (error) {
				setError(error?.response?.data?.error);
			} finally {
				setLoading(false);
			}
		};
		getSurvey();
	}, [params, surveyValidatePatientDetails]);

	const submitSurvey = async (surveyResponse, options) => {
		console.log("submit survey");
		setLoading(true);
		try {
			const requestParams = {
				body: {
					birthdate: dob,
					survey_response: surveyResponse.data,
				},
			};
			console.log(surveyResponse.data);
			console.log("API");
			await API.post("surveys", `/surveys/${params.hash}`, requestParams);
			setLoading(false);
			setIsCompleted(true);
			setShowAppointments(false);
		} catch (error) {
			console.log(error);
			setModel(null);
			setError(error?.response?.data?.error);
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const getPatientBookedAppointments = async () => {
		setLoading(true);
		try {
			// fetch booked slots for patient
			console.log("fetching booked appointments");
			let resp = await API.get("appointments", `/slots/booked/${params.hash}`);
			const bookedSlots = resp.slots;
			setBookedSlots(bookedSlots);
			setShowAppointments(true);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	//Submit Survey via API Gateway
	const onComplete = async (surveyResponse, options) => {
		//Write survey results into database
		const {receive_vaccination_1, receive_vaccination_2, receive_vaccination_3} = surveyResponse.data;
		if (receive_vaccination_1 === "YES" || receive_vaccination_2 === "YES" || receive_vaccination_3 === "YES") {
			setLoading(true);
			const requestParams = {
				body: {
					birthdate: dob,
				},
			};
			try {
				let result = await API.get("appointments", `/slots/${params.hash}/locations`, requestParams);
				console.log(result);
				setLocations(result.locations);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
			getPatientBookedAppointments();
			setResponse(surveyResponse);
			setResponseOptions(options);
		} else {
			submitSurvey(surveyResponse, options);
		}
	};

	const startSurvey = () => {
		setStarted(prev => !prev);
		setIsCompleted(false);
		setIsFeedbackSubmitted(false);
	};

	const hideHelper = () => {
		setChecked(prev => !prev);
	};

	const handleFeedbackSubmit = () => {
		setIsFeedbackSubmitted(true);
	};

	const onBookingComplete = async () => {
		await submitSurvey(response, responseOptions);
		setShowAppointments(false);
	};

	const feedbackView = () => {
		return (
			<Grid className={classes.feedbackWrap}>
				<AssignmentTurnedInOutlinedIcon className={classes.success} />
				<Typography className={classes.thankyou}>Thank you for submitting the survey</Typography>
				{!isFeedbackSubmitted ? (
					<Grid className={classes.feedbackContainer}>
						<TextareaAutosize rowsMin={5} rowsMax={5} className={classes.feedback} aria-label="feedback" placeholder="Feedback..." />
						<Button className={classes.submitButton} variant="contained" color="secondary" onClick={handleFeedbackSubmit}>
							Submit
						</Button>
					</Grid>
				) : (
					<Grid className={classes.feedbackSubmitted}>
						<Typography className={classes.thankyou}>Feedback submitted</Typography>
						<CheckCircleIcon className={classes.feedbackSent} color="secondary" />
					</Grid>
				)}
				<Grid>
					<Typography className={classes.info} component="div">
						{"You can download medloop app on "}
						<Link target="_blank" className={classes.link} href={ANDROID_LINK}>
							{"Android"}
						</Link>
						{" and "}
						<Link target="_blank" className={classes.link} href={IOS_LINK}>
							{"IOS"}
						</Link>
						.
					</Typography>
				</Grid>
			</Grid>
		);
	};

	const isAlreadyBooked = () => {
		return bookedSlots && bookedSlots.length >= 1;
	};

	const getBookedSlots = () => {
		return bookedSlots?.map((slot, i) => {
			return (
				<Box key={i}>
					<Typography className={classes.headingAcc} variant="h5">
						{slot.day}/{slot.month}/{slot.year} - {slot.hour}:{slot.minute}
					</Typography>
				</Box>
			);
		});
	};

	const showMedloopLogo = () => {
		console.log(props.width);
		if (isWidthUp("md", props.width)) {
			return (
				<Box textAlign="right">
					<Typography color="textPrimary">
						<Box display="flex" alignContent="center" justifyContent="flex-end">
							<span>In Collaboration with </span>
							<span style={{marginLeft: "8px"}}>{ICONS.MEDLOOP_LOGO_GREEN}</span>
						</Box>
					</Typography>
				</Box>
			);
		}
		return (
			<Box display="flex" flexDirection="row-reverse">
				{ICONS.MEDLOOP_LOGO_SMALL}
			</Box>
		);
	};

	const getSurveyTitle = () => {
		if (isWidthUp("md", props.width)) {
			return (
				<Box>
					<Typography component="div">
						<Box fontSize={16} color="textPrimary">
							<b>{gpName ? gpName : "GP Practice Name"}</b>&nbsp;&nbsp;&nbsp;&nbsp;{surveyName}
						</Box>
					</Typography>
				</Box>
			);
		}
		return (
			<Box>
				<Typography component="div">
					<Box fontSize={16} fontWeight="fontWeightBold" color="textPrimary">
						{gpName ? gpName : "GP Practice Name"}
					</Box>
				</Typography>
				<Typography component="div">
					<Box fontSize={16} color="textPrimary">
						{surveyName}
					</Box>
				</Typography>
			</Box>
		);
	};

	const pageLayout = () => {
		if (loading) {
			return (
				<Box className={classes.loaderWrap}>
					<Box>{ICONS.MEDLOOP_LOGO_GREEN}</Box>
					<CircularProgress color="primary" />
				</Box>
			);
		}
		if (model) {
			return (
				<div className={classes.root}>
					<Grid container spacing={3} justify="center">
						<Grid item md={6} xs={10}>
							{getSurveyTitle()}
						</Grid>
						<Grid item md={6} xs={2}>
							{showMedloopLogo()}
						</Grid>
						<Grid item md={8} xs={12}>
							<Slide timeout={500} direction="right" in={checked} mountOnEnter unmountOnExit onExited={startSurvey}>
								<Box>
									<Box className={classes.hero}>
										<Grid container justify="center">
											<Grid item md={4} className={classes.imgWrap}>
												<img alt="questionary-img" width="150" src={questionaryImg} />
											</Grid>
											<Grid item md={8} justify="center">
												<Typography align="center" component="div">
													<Box fontSize={24} color="textPrimary">
														Welcome to the {surveyName}
													</Box>
												</Typography>

												<Box mt={3}>
													<Button
														className={classes.startButton}
														variant="contained"
														color="secondary"
														endIcon={<ArrowForwardIcon />}
														onClick={hideHelper}
													>
														Begin Survey
													</Button>
												</Box>
											</Grid>
										</Grid>
									</Box>
									<Box my={2}>
										<Typography component="div">
											<Box fontSize={20} color="textPrimary">
												Why an online survey?
											</Box>
										</Typography>
									</Box>
									<Box alignItems="center" justifyContent="center">
										<List>
											<ListItem>
												<ListItemIcon>
													<QueryBuilderIcon fontSize="medium" color="secondary" />
												</ListItemIcon>
												<ListItemText
													primary={<Typography component="div">This is an online booking form for COVID19 vaccination</Typography>}
													secondary={`Your responses will be reviewed by your GP practice.`}
												/>
											</ListItem>

											<ListItem>
												<ListItemIcon>
													<LaptopIcon fontSize="medium" color="secondary" />
												</ListItemIcon>
												<ListItemText
													primary={<Typography component="div">Want to know more about the COVID19 vaccine?</Typography>}
													secondary={
														<div>
															{"Read our "}
															<Link target="_blank" className={classes.link} href={FAQ_LINK}>
																{"frequently asked questions"}
															</Link>
															{"  and find out more about the vaccine, how it was developed and what the vaccine process will be. "}
														</div>
													}
												/>
											</ListItem>

											<ListItem>
												<ListItemIcon>
													<LockIcon fontSize="medium" color="secondary" />
												</ListItemIcon>
												<ListItemText
													primary={
														<Typography component="div">
															Medloop’s NHS Digitally assured platform keeps your data safe and secure. Please read our
															<Link target="_blank" className={classes.link} href={PRIVACY_NOTICE}>
																{" privacy notice"}
															</Link>
															.
														</Typography>
													}
												/>
											</ListItem>
											<Box mt={2}>
												<ListItem selected={true}>
													<ListItemIcon>
														<ErrorOutlineIcon color="secondary" fontSize="medium" />
													</ListItemIcon>
													<ListItemText
														primary={<Typography component="div">Please Note:</Typography>}
														secondary="If you have any urgent medical attention
												please contact the practice or the appropriate resource immediately."
													/>
												</ListItem>
											</Box>
										</List>
									</Box>
								</Box>
							</Slide>
							<Slide timeout={500} direction="left" in={started} mountOnEnter unmountOnExit>
								<Survey.Survey showCompletedPage={false} model={model} css={myCss} onComplete={onComplete} />
							</Slide>
							{isCompleted ? feedbackView() : <div></div>}
							{showAppointments ? (
								isAlreadyBooked() ? (
									<>
										<Box mt={4} mb={2} className={classes.sectionHeader}>
											Appointment Booked
										</Box>
										<Typography>{getBookedSlots()}</Typography>
									</>
								) : (
									<AppointmentBooking
										setIsLoading={setSurveyValidation}
										bookedSlots={bookedSlots}
										dob={dob}
										locations={locations}
										hash={params.hash}
										onBookingComplete={onBookingComplete}
									/>
								)
							) : (
								""
							)}
						</Grid>
						<Backdrop className={classes.backdrop} open={surveyValidation}>
							<CircularProgress color="inherit" />
						</Backdrop>
					</Grid>
				</div>
			);
		}
		if (error) {
			return (
				<Box>
					<Typography variant="h3" gutterBottom>
						{error}
					</Typography>
					<Badge id="com.medloop.v1" />
					<BadgeIOS id="id1459846548" />
				</Box>
			);
		}
	};

	return <Box pb={3}>{pageLayout()}</Box>;
};

export default withWidth()(SurveyPage);

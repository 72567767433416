import React, {useEffect, useState} from "react";

import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import {Auth, API} from "aws-amplify";

import {Box, CircularProgress, Paper, Chip} from "@material-ui/core";

import {Chart, ArgumentAxis, ValueAxis, LineSeries, Title, Legend} from "@devexpress/dx-react-chart-material-ui";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {ArgumentScale, Animation} from "@devexpress/dx-react-chart";
import {curveCatmullRom, line} from "d3-shape";
import {scalePoint} from "d3-scale";

const useStyles = makeStyles(theme => ({
	root: {
		width: "1400px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		// backgroundColor: theme.palette.background.paper,
	},
	inline: {
		display: "inline",
	},
	avatar: {
		backgroundColor: "theme.palette.primary.main", // done
	},
	paper: {
		padding: theme.spacing(4),
		width: "100%",
	},
	buttonWrap: {
		textAlign: "center",
	},
}));

const Line = props => (
	<LineSeries.Path
		{...props}
		path={line()
			.x(({arg}) => arg)
			.y(({val}) => val)
			.curve(curveCatmullRom)}
	/>
);

const titleStyles = {
	title: {
		textAlign: "center",
		width: "100%",
		marginBottom: "10px",
	},
};
const Text = withStyles(titleStyles)(props => {
	const {text, classes} = props;
	const [mainText, subText] = text.split("\\n");
	return (
		<div className={classes.title}>
			<Typography component="h3" variant="h5">
				{mainText}
			</Typography>
			<Typography variant="subtitle1">{subText}</Typography>
		</div>
	);
});

const legendStyles = () => ({
	root: {
		display: "flex",
		margin: "auto",
		flexDirection: "row",
	},
});
const legendLabelStyles = theme => ({
	label: {
		marginBottom: theme.spacing(1),
		whiteSpace: "nowrap",
		fontSize: 8,
	},
});
const legendItemStyles = () => ({
	item: {
		flexDirection: "column-reverse",
	},
});

const legendRootBase = ({classes, ...restProps}) => <Legend.Root {...restProps} className={classes.root} />;
const legendLabelBase = ({classes, ...restProps}) => <Legend.Label className={classes.label} {...restProps} />;
const legendItemBase = ({classes, ...restProps}) => <Legend.Item className={classes.item} {...restProps} />;
const Root = withStyles(legendStyles, {name: "LegendRoot"})(legendRootBase);
const Label = withStyles(legendLabelStyles, {name: "LegendLabel"})(legendLabelBase);
const Item = withStyles(legendItemStyles, {name: "LegendItem"})(legendItemBase);

const getSBPAvg = (age, gender) => {
	if (age <= 25) {
		return gender === "M" ? 120.5 : 115.5;
	}
	if (age <= 30) {
		return gender === "M" ? 119.5 : 113.5;
	}
	if (age <= 35) {
		return gender === "M" ? 114.5 : 110.5;
	}
	if (age <= 40) {
		return gender === "M" ? 120.5 : 112.5;
	}
	if (age <= 45) {
		return gender === "M" ? 115.5 : 116.5;
	}
	if (age <= 50) {
		return gender === "M" ? 119.5 : 124;
	}
	if (age <= 55) {
		return gender === "M" ? 125.5 : 122.55;
	}
	if (age <= 60) {
		return gender === "M" ? 129.5 : 132.5;
	}
	return gender === "M" ? 143.5 : 130.5;
};

const getDBPAvg = (age, gender) => {
	console.log(age);
	if (age <= 25) {
		return gender === "M" ? 78.5 : 70.5;
	}
	if (age <= 30) {
		return gender === "M" ? 76.5 : 71.5;
	}
	if (age <= 35) {
		return gender === "M" ? 75.5 : 72.5;
	}
	if (age <= 40) {
		return gender === "M" ? 75.5 : 74.5;
	}
	if (age <= 45) {
		return gender === "M" ? 78.5 : 73.5;
	}
	if (age <= 50) {
		return gender === "M" ? 80.5 : 78.5;
	}
	if (age <= 55) {
		return gender === "M" ? 80.5 : 74.5;
	}
	if (age <= 60) {
		return gender === "M" ? 79.5 : 78.5;
	}
	return gender === "M" ? 76.5 : 77.5;
};
const getAge = (dateString, dateToCalc) => {
	if (!dateString) return 0;
	console.log(dateString);
	let dates = dateString.split("/");
	dateString = `${dates[2]}-${dates[1]}-${dates[0]}`;
	let datesCalc = dateToCalc.split("/");
	dateToCalc = `${datesCalc[2]}-${datesCalc[1]}-${datesCalc[0]}`;
	var today = new Date(dateToCalc);
	var birthDate = new Date(dateString);
	var age = today.getFullYear() - birthDate.getFullYear();
	var m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	console.log(age);
	return age;
};

export default function PatientEMR(props) {
	const classes = useStyles();
	const [data, setData] = useState([]);
	const [events, setEvents] = useState({});
	const [problems, setProblems] = useState([]);
	const [medications, setMedications] = useState([]);
	const [entries, setEntries] = useState({});
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(false);
	const {
		match: {params},
	} = props;

	useEffect(() => {
		if (user) return;
		const getUser = async () => {
			try {
				setLoading(true);
				const _user = await Auth.currentAuthenticatedUser();
				setUser(_user);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};
		getUser();
	}, [user]);

	useEffect(() => {
		if (!user) return;
		setLoading(true);
		console.log(params);
		const getData = async () => {
			try {
				const requestParams = {
					headers: {
						"m-emis-user": user.attributes["custom:emis_login"],
					},
				};
				let response = await API.get("pimi", `/pimi/${params.odsCode}/patients/${params.nhsNumber}/emr`, requestParams);

				console.log(response);
				if (!response) {
					return;
				}
				setData(response);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};
		getData();
	}, [user, params]);

	useEffect(() => {
		if (!user) return;
		const groupEvents = async () => {
			let gender = data.Registration?.Sex;
			try {
				setLoading(true);
				const groupedEvents = data.EventList.Event.filter(
					event => event.EventType === 5 && event.TermID?.MapCode && event.NumericValue
				).reduce((acc, event) => {
					let age = getAge(data.Registration?.DateOfBirth, event.AssignedDate);
					acc[event.TermID.MapCode] = [
						...(acc[event.TermID.MapCode] || []),
						{
							value: event.NumericValue.Value,
							date: event.AssignedDate,
							term: event.TermID.Term,
							median: event.TermID.MapCode === "1091811000000102" ? getDBPAvg(age, gender) : getSBPAvg(age, gender),
						},
					];
					return acc;
				}, {});

				console.log(groupedEvents);
				setEvents(groupedEvents);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};
		groupEvents();
	}, [data, user]);

	useEffect(() => {
		if (!user) return;
		const getProblems = async () => {
			try {
				setLoading(true);
				const filteredProblems = data.EventList.Event.filter(event => event.EventType === 1 && event.Problem);
				console.log(filteredProblems);
				setProblems(filteredProblems);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};
		getProblems();
	}, [data, user]);

	useEffect(() => {
		if (!user) return;
		const getMedications = async () => {
			try {
				setLoading(true);
				const meds = data.MedicationList.Medication.filter(med => med.Status !== "P");
				console.log(meds);
				setMedications(meds);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};
		getMedications();
	}, [data, user]);

	useEffect(() => {
		if (!user) return;
		const groupConsultationElements = async () => {
			try {
				setLoading(true);
				const groupedElements = data.ConsultationList.Consultation.reduce((acc, consultation) => {
					if (consultation.ElementList?.ConsultationElement) {
						return [...acc, ...consultation.ElementList.ConsultationElement];
					}
					return acc;
				}, [])
					.filter(consultation => consultation.Event?.NumericValue && consultation.Event?.TermID)
					.reduce((acc, consultation) => {
						acc[consultation.Event.TermID.MapCode] = [
							...(acc[consultation.Event.TermID.MapCode] || []),
							{
								value: consultation.Event.NumericValue.Value,
								date: consultation.Event.AssignedDate,
								term: consultation.Event.TermID.Term,
							},
						];
						return acc;
					}, {});
				//1091811000000102
				console.log(groupedElements);
				setEntries(groupedElements);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};
		groupConsultationElements();
	}, [data, user]);

	const pageLayout = () => {
		if (loading) {
			return (
				<Box className={classes.loaderWrap}>
					<CircularProgress color="primary" />
				</Box>
			);
		}
		if (data) {
			//return <Box>{JSON.stringify(events, null, 2)}</Box>;
			return (
				<Grid container>
					<Paper elevation={3}>
						<Box m={2} p={2}>
							<Typography variant="h6">
								<b>
									{data.Registration?.FirstNames} {data.Registration?.FamilyName}
								</b>{" "}
								- {data.Registration?.Sex}, {data.Registration?.DateOfBirth} <b>|</b> NHS number: <b>{data.Registration?.NhsNumber}</b>
							</Typography>
						</Box>
					</Paper>

					<Grid item md={12}>
						<Box p={2}>
							{problems
								.filter(problem => problem.Problem.ProblemStatus === 1)
								.map(problem => {
									return <Chip style={{backgroundColor: "red", color: "white", margin: "1px"}} label={problem.TermID.Term} />;
								})}
							{problems
								.filter(problem => problem.Problem.ProblemStatus !== 1)
								.map(problem => {
									return <Chip style={{margin: "1px"}} label={problem.TermID.Term} />;
								})}
						</Box>
					</Grid>
					<Grid item md={12}>
						<Paper elevation={3}>
							<Box m={2} p={2}>
								{medications.map(med => {
									return (
										<Typography>
											<b>{med.Drug.PreparationID.Term}</b> - {med.Dosage}
										</Typography>
									);
								})}
							</Box>
						</Paper>
					</Grid>
					{events["1091811000000102"] ? (
						<Grid item md={12}>
							<Chart data={events["1091811000000102"]} className={classes.chart}>
								<ArgumentScale factory={scalePoint} />
								<ArgumentAxis />
								<ValueAxis />

								<LineSeries name="Systolic Blood Presure" valueField="value" argumentField="date" seriesComponent={Line} />
								<LineSeries name="Median" valueField="median" argumentField="date" seriesComponent={Line} />

								<Legend position="bottom" rootComponent={Root} itemComponent={Item} labelComponent={Label} />
								<Title text="Systolic Blood Presure" textComponent={Text} />
								<Animation />
							</Chart>
						</Grid>
					) : (
						""
					)}

					{events["72313002"] ? (
						<Grid item md={12}>
							<Chart data={events["72313002"]} className={classes.chart}>
								<ArgumentScale factory={scalePoint} />
								<ArgumentAxis />
								<ValueAxis />

								<LineSeries name="Diastolic Blood Pressure" valueField="value" argumentField="date" seriesComponent={Line} />
								<LineSeries name="Median" valueField="median" argumentField="date" seriesComponent={Line} />
								<Legend position="bottom" rootComponent={Root} itemComponent={Item} labelComponent={Label} />
								<Title text="Diastolic Blood Pressure" textComponent={Text} />
								<Animation />
							</Chart>
						</Grid>
					) : (
						""
					)}
					{entries["27113001"] ? (
						<Grid item md={12}>
							<Chart data={entries["27113001"]} className={classes.chart}>
								<ArgumentScale factory={scalePoint} />
								<ArgumentAxis />
								<ValueAxis />

								<LineSeries name="Weight" valueField="value" argumentField="date" color="green" seriesComponent={Line} />
								<Legend position="bottom" rootComponent={Root} itemComponent={Item} labelComponent={Label} />
								<Title text="Weight" textComponent={Text} />
								<Animation />
							</Chart>
						</Grid>
					) : (
						""
					)}
				</Grid>
			);
		}
	};

	return (
		<Grid container justify="center" spacing={4} className={classes.root}>
			<Paper variant="elevation" className={classes.paper}>
				{pageLayout()}
			</Paper>
		</Grid>
	);
}

import React, {useEffect, useState, useCallback, useRef} from "react";
import {useSnackbar} from "notistack";
import moment from "moment";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import {API, graphqlOperation, Auth} from "aws-amplify";

import {sendCustomSMS, adminCancelAppointment} from "../graphql/customMutations";

import {listSlotss, controlledPatientByHash, slotsByStatus, locationByGpAndPostCode} from "../graphql/queries";
import {updateSlots, createSlots, updateControlledPatient, deleteSlots} from "../graphql/mutations";
import {
	Box,
	Typography,
	Button,
	Grid,
	CircularProgress,
	Backdrop,
	TextField,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Avatar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {makeStyles, withStyles, useTheme} from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import {useHistory} from "react-router-dom";

import {useJsonToCsv} from "react-json-csv";

import "../App.css";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import CakeIcon from "@material-ui/icons/Cake";
import PhoneIcon from "@material-ui/icons/Phone";
import IconButton from "@material-ui/core/IconButton";
import SmsIcon from "@material-ui/icons/Sms";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import ModifyBookings from "../components/ModifyBookings.js";
import SlotFilter from "../components/SlotFilter";

import {getCSV} from "../utils/appointmentsActions";
import BatchAppointments from "../dialogs/BatchAppointments";
import SendMessageDialog from "../dialogs/SendMessageDialog";
import CompleteAppointments from "../dialogs/CompleteAppointment";

const fetch = require("node-fetch");

const {saveAsCsv} = useJsonToCsv();

const days = {
	SUNDAY: "SUNDAY",
	MONDAY: "MONDAY",
	TUESDAY: "TUESDAY",
	WEDNESDAY: "WEDNESDAY",
	THURSDAY: "THURSDAY",
	FRIDAY: "FRIDAY",
	SATURDAY: "SATURDAY",
};

const HtmlTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: "#fff",
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 600,
		fontSize: theme.typography.pxToRem(12),
		// border: "1px solid #dadde9",
		padding: theme.spacing(3),
		boxShadow: "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)",
		zIndex: 1400,
	},
}))(Tooltip);

const CreateSlot = props => {
	const {handleClose, submitSlot, startDate, classes, locations, duration} = props;

	const handleChange = prop => event => {
		event.preventDefault();
		setValues({...values, [prop]: event.target.value});
	};

	const [values, setValues] = React.useState({
		slotDate: startDate ? moment(startDate).format("yyyy-MM-DD") : moment().format("yyyy-MM-DD"),
		slotTime: startDate ? moment(startDate).format("HH:mm") : "12:00",
		year: "",
		month: "",
		day: "",
		hour: "",
		minute: "",
		duration: duration,
		version: "V_0",
		reason: "",
		title: "Covid",
		status: "AVAILABLE",
		location: locations[0],
	});

	return (
		<>
			<DialogTitle id="form-dialog-title">
				Create an Availability Slot
				<IconButton
					className={classes.closeButton}
					onClick={() => handleClose()}
					color="primary"
					aria-label="upload picture"
					component="span"
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<DialogContentText>Vaccination Slots</DialogContentText>
				<Grid container spacing={1}>
					<Grid item md={12} xs={12}>
						<TextField
							autoFocus
							margin="dense"
							id="slots"
							variant="outlined"
							label="Date"
							type="date"
							fullWidth
							defaultValue={values.slotDate}
							onChange={handleChange("slotDate")}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Grid>
					<Grid item md={12} xs={12}>
						<TextField
							id="time"
							variant="outlined"
							margin="dense"
							label="Time"
							type="time"
							fullWidth
							defaultValue={values.slotTime}
							onChange={handleChange("slotTime")}
							InputLabelProps={{
								shrink: true,
							}}
							inputProps={{
								step: 300, // 5 min
							}}
						/>
					</Grid>
					<Grid item md={12} xs={12}>
						<TextField
							label="Duration (minutes)"
							type="number"
							margin="dense"
							inputProps={{min: "15", max: "90", step: "15"}}
							value={values.duration}
							onChange={handleChange("duration")}
							fullWidth
							variant="outlined"
						/>
					</Grid>
					<Grid item md={12} xs={12}>
						<TextField
							label="Locations"
							select
							fullWidth
							margin="dense"
							variant="outlined"
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={values.location}
							onChange={handleChange("location")}
						>
							{locations.map(location => (
								<MenuItem key={location.staff_id} value={location}>
									{location.name}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item md={12} xs={12}>
						<TextField
							autoFocus
							margin="dense"
							label="Reason"
							value={values.reason}
							onChange={handleChange("reason")}
							fullWidth
							variant="outlined"
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleClose()} color="primary">
					Cancel
				</Button>
				<Button onClick={() => submitSlot(values)} color="primary" disabled={values.duration < 5 || values.duration > 90}>
					Add Slot
				</Button>
			</DialogActions>
		</>
	);
};

const SendMessage = props => {
	const {handleClose, sendNotif, info} = props;

	const handleChange = prop => event => {
		event.preventDefault();
		setValues({...values, [prop]: event.target.value});
	};

	const [values, setValues] = React.useState({
		message: "",
	});
	return (
		<>
			<DialogTitle id="form-dialog-title">Message Patient via SMS</DialogTitle>
			<DialogContent>
				<Grid container spacing={1}>
					<Grid item md={12} xs={12}>
						<TextField
							autoFocus
							margin="dense"
							label="Message"
							multiline
							rows={5}
							value={values.message}
							onChange={handleChange("message")}
							fullWidth
							variant="outlined"
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleClose()} color="primary">
					Cancel
				</Button>

				<Button
					onClick={() => sendNotif(info.event.extendedProps.slot, values, "CUSTOM")}
					color="primary"
					disabled={values.message.length === 0 || values.message.trim().length === 0}
				>
					Submit
				</Button>
			</DialogActions>
		</>
	);
};

const CompleteAndSaveToEmis = props => {
	const {handleClose, complete, saveToEmis, info} = props;
	const {enqueueSnackbar} = useSnackbar();
	const [values, setValues] = React.useState({
		comments: "",
	});

	const handleChange = prop => event => {
		event.preventDefault();
		setValues({...values, [prop]: event.target.value});
	};

	const submit = async () => {
		try {
			info.event.extendedProps.slot["comments"] = values.comments;
			await complete(info);
			if (!info.event?.extendedProps?.slot?.patient_hash) return;

			await saveToEmis(info.event.extendedProps.slot.patient_hash, values.comments);
		} catch (error) {
			console.log(error);
			enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
		} finally {
			handleClose();
		}
	};
	return (
		<>
			<DialogTitle id="form-dialog-title">Are you sure you want to complete this vaccination and file a entry in EMIS?</DialogTitle>
			<DialogContent>
				<Grid container spacing={1}>
					<Grid item md={12} xs={12}>
						<TextField
							autoFocus
							margin="dense"
							label="Consultation Comments"
							multiline
							rows={5}
							value={values.comments}
							onChange={handleChange("comments")}
							fullWidth
							variant="outlined"
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleClose()} color="primary">
					Cancel
				</Button>

				<Button onClick={() => submit()} color="primary">
					Submit
				</Button>
			</DialogActions>
		</>
	);
};

const useStyles = makeStyles(theme => ({
	eventInfoToolTip: {
		background: "#E6E7EB",
	},
	backdrop: {
		zIndex: 9999,
		color: "#000",
	},
	dialogTitle: {
		padding: theme.spacing(0),
		paddingBottom: theme.spacing(2),
		fontSize: 24,
		fontWeight: 500,
		color: theme.palette.secondary.dark,
	},
	dialogContent: {
		padding: theme.spacing(0),
		overflowY: "unset",
	},
	dialogSub: {
		paddingBottom: theme.spacing(0.5),
		fontSize: 16,
		marginBottom: theme.spacing(0),
		color: theme.palette.secondary.dark,
	},
	dialogContentGroup: {
		padding: theme.spacing(2, 0),
	},
	dialogFooter: {
		padding: theme.spacing(0),
	},
	closeButton: {
		cursor: "pointer",
		float: "right",
	},
	eventInfo: {
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		cursor: "pointer",
	},
}));

const AppointmentsPage = props => {
	const [user, setUser] = useState(null);
	const [open, setOpen] = useState(false);
	const [duration, setDuration] = useState("30");
	const [batchCreateOpen, setBatchCreateOpen] = useState(false);
	const [clickedDateTime, setClickedDateTime] = useState(null);
	const [notifyInfo, setNotifyInfo] = useState(null);
	const [notifyDialogOpen, setNotifyDialogOpen] = useState(false);
	const [completeOpen, setCompleteOpen] = useState(false);
	const [modifyOpen, setModifyOpen] = useState(false);
	const [modifyPatientGuid, setModifyPatientGuid] = useState({patient_guid: "", patient_hash: "", reason: "", patient_title: ""});
	const [locations, setLocations] = useState([]);

	const cal = props.location?.state?.cal;

	const [loading, setLoading] = useState(false);
	const [gpLocation, setGpLocation] = React.useState(null);
	const [status, setStatus] = React.useState(null);

	const classes = useStyles();
	const calendarRef = useRef(null);

	let history = useHistory();
	const {enqueueSnackbar} = useSnackbar();

	const getToCompleteSlot = () => {
		return [notifyInfo.event.extendedProps.slot];
	};

	const handleSelect = async selectedInfo => {
		const {endStr, startStr} = selectedInfo;
		let duration = moment(endStr).diff(moment(startStr), "minutes");
		await Promise.all([setDuration(duration), setClickedDateTime(startStr), setOpen(true)]);
	};
	const validateSelect = selectedInfo => {
		const {start} = selectedInfo;
		var now = new Date();
		if (start < now) {
			enqueueSnackbar("Cannot create slot for past dates", {variant: "info"});
		}
		return now < start;
	};

	const handleClose = () => {
		setOpen(false);
		setClickedDateTime(null);
	};

	const handleBatchCreateClose = () => setBatchCreateOpen(false);
	const handleNotifyDialogClose = () => setNotifyDialogOpen(false);
	const handleCompleteClose = () => setCompleteOpen(false);
	const handleModifyClose = () => {
		setModifyOpen(false);
		setModifyPatientGuid(null);
	};

	useEffect(() => {
		if (user) return;
		const getUser = async () => {
			try {
				// setIsLoading(true);
				const _user = await Auth.currentAuthenticatedUser();
				setUser(_user);
			} catch (error) {
				console.log(error);
				enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
			} finally {
				// setIsLoading(false);
			}
		};
		getUser();
	}, [enqueueSnackbar, user]);

	useEffect(() => {
		if (!user) return;
		const getLocations = async () => {
			try {
				const result = await API.graphql(
					graphqlOperation(locationByGpAndPostCode, {
						gp_guid: user.attributes["custom:gp_guid"],
					})
				);

				setLocations(result.data.locationByGPAndPostCode.items);
			} catch (error) {
				console.log(error);
			} finally {
				// setIsLoading(false);
			}
		};
		getLocations();
	}, [user]);

	const submitSlot = async values => {
		try {
			setLoading(true);
			const user = await Auth.currentAuthenticatedUser();
			values.gp_guid = user.attributes["custom:gp_guid"];
			let dateSplit = values.slotDate.split("-");
			values.year = dateSplit[0];
			values.month = dateSplit[1];
			values.day = dateSplit[2];
			let hourSplit = values.slotTime.split(":");
			values.hour = hourSplit[0];
			values.minute = hourSplit[1];
			values.staff_id = values.location.staff_id;
			delete values.location;
			delete values.slotDate;
			delete values.slotTime;
			values.location_details = "DEPRECATED";
			console.log(values);
			const res = await API.graphql(graphqlOperation(createSlots, {input: values}));
			const slot = res.data.createSlots;

			const {id, patient_guid, reason, status, year, month, day, hour, minute, duration} = slot;
			const start = moment(`${year}-${month}-${day}T${hour}:${minute}`);

			calendarRef.current.getApi().addEvent({
				id: `${values.day}#${hour}#${patient_guid}#${id}`,
				title: status,
				start: start.format(),
				end: start.add(duration, "minute").format(),
				extendedProps: {
					slot: slot,
				},
				description: reason,
				color: "#d7d9e0",
				textColor: "transparent",
			});
			enqueueSnackbar("Availability slot has been created", {variant: "success"});
		} catch (err) {
			console.log(err);
			enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
		} finally {
			setOpen(false);
			setLoading(false);
		}
	};

	const getEvents = useCallback(
		async (calendarInfo, successCallback, errorCallback) => {
			if (!user) return [];
			try {
				let filter = {
					...(gpLocation && {staff_id: {eq: gpLocation.staff_id}}),
					...(status && {status: {eq: status}}),
				};
				if (Object.keys(filter).length === 0 && filter.constructor === Object) {
					filter = null;
				}
				const result = await API.graphql(
					graphqlOperation(listSlotss, {
						gp_guid: user.attributes["custom:gp_guid"],
						...(filter && {filter: filter}),
						limit: 1000,
					})
				);

				const events = result.data.listSlotss.items.map(slot => {
					const {patient_guid, title, reason, status, year, month, day, hour, minute, duration} = slot;
					const start = moment.utc(`${year}-${month}-${day}T${hour}:${minute}`);
					const event = {
						id: `${day}#${hour}#${patient_guid}`,
						title: status === "BOOKED" ? title : status,
						start: start.format(),
						end: start.add(duration, "minute").format(),
						extendedProps: {
							slot: slot,
						},
						description: reason,
						color: status === "BOOKED" ? "#4d567f" : status === "CANCELLED" ? "#e08a8c" : status === "COMPLETED" ? "#a3e1d2" : "#d7d9e0",
						textColor:
							status === "BOOKED" ? "#fff" : status === "CANCELLED" ? "#c91508" : status === "COMPLETED" ? "#158972" : "transparent",
					};
					return event;
				});

				successCallback(events);
			} catch (error) {
				console.log(error);
				errorCallback(error);
				enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
			}
		},
		[enqueueSnackbar, user, gpLocation, status]
	);

	const updateStatus = async ({slot, from_status, to_status}) => {
		try {
			slot.status = to_status;
			slot.staff_id = slot.location.staff_id;
			delete slot.location;
			const res = await API.graphql(
				graphqlOperation(updateSlots, {
					input: slot,
					...(from_status && {condition: {status: {eq: from_status}}}),
				})
			);
			calendarRef.current.getApi().refetchEvents();
			return res;
		} catch (error) {
			enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
		}
	};

	const sendNotif = async (slot, values, type) => {
		console.log({slot});
		console.log({notifyInfo});
		console.log({reason: slot.reason});
		console.log({values});

		// push to SQS instead
		try {
			await API.graphql(
				graphqlOperation(sendCustomSMS, {
					input: JSON.stringify({
						patientGuid: slot.patient_guid,
						gpGuid: slot.gp_guid,
						sourceId: JSON.parse(slot.reason).source_id,
						type,
						content: values.message,
					}),
				})
			);
			enqueueSnackbar("SMS sucessfully sent.", {variant: "success"});
			setNotifyDialogOpen(false);
		} catch (err) {
			console.log(`ERROR: ${JSON.stringify(err)}`);
			enqueueSnackbar("Error sending SMS", {variant: "error"});
		}
	};

	const complete = async info => {
		await updateStatus({slot: info.event.extendedProps.slot, from_status: "BOOKED", to_status: "COMPLETED"});
	};

	const cancel = async info => {
		setLoading(true);
		try {
			let _slot = info.event.extendedProps.slot;
			await API.graphql(
				graphqlOperation(adminCancelAppointment, {
					input: JSON.stringify({
						patientGuid: _slot.patient_guid,
						gpGuid: _slot.gp_guid,
						sourceId: JSON.parse(_slot.reason).source_id,
						slotId: _slot.id,
					}),
				})
			);
			enqueueSnackbar("Slot cancelled successfully.", {variant: "success"});
			calendarRef.current.getApi().refetchEvents();
		} catch (e) {
			enqueueSnackbar("Error cancelling slot", {variant: "error"});
		} finally {
			setLoading(false);
		}
	};

	const deleteEvent = async info => {
		try {
			//TODO: call api to delete the slot
			console.log("input", info.event.extendedProps.slot);
			await API.graphql(
				graphqlOperation(deleteSlots, {
					input: {
						id: info.event.extendedProps.slot.id,
					},
				})
			);
			calendarRef.current.getApi().refetchEvents();
			enqueueSnackbar("Slot deleted successfully.", {variant: "success"});
		} catch (error) {
			console.log(error);
			enqueueSnackbar("Failed to delete slot.", {variant: "error"});
		}
	};

	const gotToPatient = async info => {
		let slot = info.event.extendedProps.slot;
		const result = await API.graphql(
			graphqlOperation(controlledPatientByHash, {
				hash: slot.patient_hash,
				gp_guid: {
					eq: user.attributes["custom:gp_guid"],
				},
			})
		);
		console.log(result);
		let controlledPatient = result.data.controlledPatientByHash.items[0];
		console.log(controlledPatient);
		history.push(`/dashboard/emr/${controlledPatient.gp_ods_code}/${controlledPatient.nhs_number.replace(/\s/g, "")}`);
	};

	const openNotifyDialog = info => {
		setNotifyInfo(info);
		setNotifyDialogOpen(true);
	};

	const openCompleteDialog = info => {
		setNotifyInfo(info);
		setCompleteOpen(true);
	};

	const openModifyDialog = (info, patient_guid, patient_hash, reason, patient_title) => {
		setNotifyInfo(info);
		setModifyPatientGuid({patient_guid: patient_guid, patient_hash: patient_hash, reason: reason, patient_title: patient_title});
		setModifyOpen(true);
	};

	const EventComp = ({eventInfo, classes}) => {
		const [toolTipOpen, setToolTipOpen] = useState(false);
		const theme = useTheme();

		const handleTooltipClose = () => {
			setToolTipOpen(false);
		};

		const handleTooltipOpen = () => {
			setToolTipOpen(true);
		};
		let completeDisabled,
			canModify,
			canMessage,
			canCancel,
			canDelete = true;
		const {startStr} = eventInfo.event;
		let patient_details;
		let location_details;
		let status;
		let patient_guid;
		let patient_hash;
		let reason;
		let patient_title;

		if (eventInfo.event.extendedProps.slot) {
			status = eventInfo.event.extendedProps.slot.status;
			completeDisabled = status === "BOOKED" && moment().diff(moment(startStr)) > 0;
			canModify = status === "BOOKED" && moment().diff(moment(startStr)) < 0;
			canCancel = status === "BOOKED";
			canDelete = status === "AVAILABLE";
			canMessage = status === "BOOKED" || status === "COMPLETED";
		}

		try {
			location_details = JSON.parse(eventInfo?.event.extendedProps.slot?.location_details);
		} catch {
			//
		}

		if (canMessage || canModify) {
			try {
				patient_details = JSON.parse(eventInfo?.event.extendedProps.slot?.reason);
			} catch (err) {
				//
			}
		}

		if (canModify) {
			try {
				patient_guid = eventInfo?.event.extendedProps.slot.patient_guid;
				patient_hash = eventInfo?.event.extendedProps.slot.patient_hash;
				reason = eventInfo?.event.extendedProps.slot?.reason;
				patient_title = eventInfo?.event.extendedProps.slot?.title;
			} catch (err) {
				console.log(err);
			}
		}

		return (
			<ClickAwayListener onClickAway={handleTooltipClose}>
				<HtmlTooltip
					key={eventInfo.event.extendedProps.slot_id}
					onClose={handleTooltipClose}
					open={toolTipOpen}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					title={
						<React.Fragment>
							<Box m={1}>
								{patient_details && canMessage ? (
									<Typography color="textPrimary" variant="h5">
										{patient_details.patient_name} {patient_details.patient_surname} {status === "COMPLETED" ? "(COMPLETED)" : ""}
									</Typography>
								) : (
									<Typography
										variant="h5"
										color={
											status === "CANCELLED"
												? theme.palette.error.main
												: status === "COMPLETED"
												? theme.palette.success.main
												: "textPrimary"
										}
									>
										{status}
									</Typography>
								)}
								<List dense aria-label="main mailbox folders">
									<ListItem>
										<ListItemIcon>
											<QueryBuilderIcon />
										</ListItemIcon>
										<ListItemText primary={eventInfo.timeText} />
									</ListItem>
									<ListItem>
										<ListItemIcon>
											<CalendarTodayIcon />
										</ListItemIcon>
										<ListItemText primary={moment(eventInfo.event.startStr).format("ddd, DD-MM-YYYY")} />
									</ListItem>
									<ListItem>
										<ListItemIcon>
											<TimelapseIcon />
										</ListItemIcon>
										<ListItemText primary={`${moment(eventInfo.event.endStr).diff(moment(eventInfo.event.startStr), "minute")} minutes`} />
									</ListItem>
									<ListItem>
										<ListItemIcon>
											<LocalHospitalIcon />
										</ListItemIcon>
										<ListItemText primary={eventInfo?.event?.extendedProps?.slot?.location?.name} />
									</ListItem>
									{patient_details && (
										<>
											<ListItem>
												<ListItemIcon>
													<CakeIcon />
												</ListItemIcon>
												<ListItemText primary={patient_details.patient_birthdate} />
											</ListItem>
											<ListItem>
												<ListItemIcon>
													<PhoneIcon />
												</ListItemIcon>
												<ListItemText primary={patient_details.patient_mobile} />
											</ListItem>
										</>
									)}
								</List>
								<Box my={1} display="flex" justifyContent="flex-end">
									<IconButton
										style={{marginRight: "4px"}}
										size="small"
										aria-label="sms"
										disabled={!canMessage}
										onClick={() => openNotifyDialog(eventInfo)}
									>
										<SmsIcon />
									</IconButton>
									<IconButton
										style={{marginRight: "4px"}}
										size="small"
										aria-label="sms"
										disabled={!canMessage}
										onClick={() => gotToPatient(eventInfo)}
									>
										<AccountBoxIcon />
									</IconButton>
									{canDelete ? (
										<Button
											style={{marginRight: "4px"}}
											size="small"
											color="secondary"
											variant="contained"
											onClick={() => deleteEvent(eventInfo)}
										>
											Delete
										</Button>
									) : (
										<Button
											style={{marginRight: "4px"}}
											size="small"
											color="secondary"
											variant="contained"
											disabled={!canCancel}
											onClick={() => cancel(eventInfo)}
										>
											Cancel
										</Button>
									)}
									{canModify ? (
										<Button
											size="small"
											color="primary"
											variant="contained"
											// disabled={!completeDisabled}
											onClick={() => openModifyDialog(eventInfo, patient_guid, patient_hash, reason, patient_title)}
										>
											Modify
										</Button>
									) : (
										<Button
											size="small"
											color="primary"
											variant="contained"
											disabled={!completeDisabled}
											onClick={() => openCompleteDialog(eventInfo)}
										>
											Complete
										</Button>
									)}
								</Box>
								{location_details && (
									<React.Fragment>
										<Typography color="textPrimary">City: {location_details.city}</Typography>
										<Typography color="textPrimary">Address: {location_details.address}</Typography>
										<Typography color="textPrimary">Post Code: {location_details.post_code}</Typography>
										<Typography color="textPrimary">GP Phone: {location_details.gp_phone}</Typography>
									</React.Fragment>
								)}
							</Box>
						</React.Fragment>
					}
					// arrow
					placement="right"
					interactive
				>
					<div className={classes.eventInfo} onClick={handleTooltipOpen}>
						<b>{eventInfo.timeText}</b> - <i>{eventInfo.event.title}</i>
					</div>
				</HtmlTooltip>
			</ClickAwayListener>
		);
	};

	const renderEventContent = eventInfo => {
		return <EventComp classes={classes} eventInfo={eventInfo} />;
	};

	return (
		<div className={classes.root}>
			<SlotFilter setGpLocation={setGpLocation} setStatus={setStatus} />
			<Box m={2}>
				<FullCalendar
					timeZone={"UTC"}
					ref={calendarRef}
					plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
					customButtons={{
						// addSlot: {
						// 	text: "Create Slot",
						// 	click: function() {
						// 		setOpen(true);
						// 	},
						// },
						addBatchSlot: {
							text: "Create Appointment Series",
							click: function() {
								setBatchCreateOpen(true);
							},
						},
						csvDownload: {
							text: "Export Appointment Data",
							click: function() {
								getCSV(user, setLoading, enqueueSnackbar);
							},
						},
						focus: {
							text: "Day",
							click: function() {
								history.push(`/dashboard/myslots`);
							},
						},
					}}
					headerToolbar={{
						left: "prev,next today",
						center: "title",
						right: "addBatchSlot csvDownload focus,timeGridWeek,dayGridMonth",
					}}
					initialView={cal ? cal : "timeGridWeek"}
					editable={false}
					selectAllow={validateSelect}
					selectable={true}
					selectMirror={true}
					dayMaxEvents={true}
					dayHeaderFormat={{
						day: "numeric",
						weekday: "short",
					}}
					weekends={false}
					events={getEvents}
					height="auto"
					slotMinTime="07:00:00"
					slotMaxTime="20:00:00"
					businessHours={{
						daysOfWeek: [1, 2, 3, 4, 5],
						startTime: "07:00",
						endTime: "20:00",
					}}
					select={handleSelect}
					nowIndicator={true}
					eventContent={renderEventContent}
					eventTimeFormat={{
						hour: "2-digit",
						minute: "2-digit",
						hour12: false,
					}}
					slotLabelFormat={{
						hour: "2-digit",
						minute: "2-digit",
						hour12: false,
					}}
				/>
				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
					<CreateSlot
						locations={locations}
						classes={classes}
						startDate={clickedDateTime}
						duration={duration}
						submitSlot={submitSlot}
						handleClose={handleClose}
					/>
				</Dialog>
				<BatchAppointments
					isOpen={batchCreateOpen}
					setIsOpen={setBatchCreateOpen}
					setLoading={setLoading}
					callback={() => calendarRef.current.getApi().refetchEvents()}
				/>
				<SendMessageDialog slot={notifyInfo?.event.extendedProps.slot} handleClose={handleNotifyDialogClose} isOpen={notifyDialogOpen} />
				<CompleteAppointments
					isOpen={completeOpen}
					setIsOpen={setCompleteOpen}
					setLoading={setLoading}
					getSlots={getToCompleteSlot}
					callback={() => calendarRef.current.getApi().refetchEvents()}
				/>
				<Dialog open={modifyOpen} onClose={handleModifyClose} aria-labelledby="complete-dialog">
					<ModifyBookings
						patient_ids={modifyPatientGuid}
						user={user}
						handleClose={handleModifyClose}
						setLoadingBackdrop={setLoading}
						refreshEvents={() => calendarRef.current.getApi().refetchEvents()}
					/>
				</Dialog>
				<Backdrop className={classes.backdrop} open={loading}>
					<CircularProgress color="primary" />
				</Backdrop>
			</Box>
		</div>
	);
};

export default AppointmentsPage;

import React from "react";
import {Route, Switch} from "react-router-dom";
import awsExports from "./aws-exports";
import Amplify from "aws-amplify";
import {AuthState, onAuthUIStateChange} from "@aws-amplify/ui-components";
import MedloopMenu from "./components/Menu.js";
import AddSourcePage from "./screens/AddSource.js";
import ControlledPatientList from "./screens/ControlledPatientList.js";
import UploadPage from "./pages/Upload.js";
import LoginPage from "./pages/Login.js";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import AppointmentsPage from "./screens/Appointments";
import ManageSlots from "./pages/ManageSlots";
import MySlots from "./pages/MySlots";
import PatientEMR from "./screens/PatientEMR.js";

Amplify.configure(awsExports);

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		height: "100%",
	},
	wrapper: {
		padding: theme.spacing(3),
		height: "100%",
		paddingTop: theme.spacing(4),
	},
	content: {
		flexGrow: 1,
		// padding: theme.spacing(3),
		height: "100%",
		backgroundColor: theme.palette.background.paper,
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),

		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	main: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(0),
	},
}));

const Dashboard = ({match}) => {
	const classes = useStyles();
	const [authState, setAuthState] = React.useState();
	const [user, setUser] = React.useState();
	React.useEffect(() => {
		return onAuthUIStateChange((nextAuthState, authData) => {
			setAuthState(nextAuthState);
			setUser(authData);
		});
	}, []);

	return (
		<div className={classes.root}>
			{authState === AuthState.SignedIn && user ? (
				<React.Fragment>
					<MedloopMenu />
					<div className={classes.content}>
						<div className={classes.toolbar}></div>
						<Box className={classes.main}>
							<Switch>
								<Route exact path="/dashboard/" component={ControlledPatientList} />
								<Route exact path="/dashboard/addsource" component={AddSourcePage} />
								<Route exact path="/dashboard/upload" component={UploadPage} />
								<Route exact path="/dashboard/appointments" component={AppointmentsPage} />
								<Route exact path="/dashboard/slots" component={ManageSlots} />
								<Route exact path="/dashboard/myslots" component={MySlots} />
								<Route exact path="/dashboard/emr/:odsCode/:nhsNumber" component={PatientEMR} />
							</Switch>
						</Box>
					</div>
				</React.Fragment>
			) : (
				<LoginPage />
			)}
		</div>
	);
};

export default Dashboard;

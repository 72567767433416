import React, {useEffect, useState, useCallback, useMemo} from "react";
import moment from "moment";
import {API, Auth, graphqlOperation} from "aws-amplify";
import Moment from "moment";
import {useSnackbar} from "notistack";

import {makeStyles} from "@material-ui/core/styles";

import {
	Box,
	Button,
	CircularProgress,
	Drawer,
	FormControl,
	IconButton,
	MenuItem,
	Select,
	Tab,
	Tabs,
	Typography,
	LinearProgress,
	Tooltip,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import LaunchIcon from "@material-ui/icons/Launch";
import SendIcon from "@material-ui/icons/Send";

import ConfirmDialog from "../components/ConfirmDialog";

import PropTypes from "prop-types";

import {sourceByGp, slotsByPatient, locationByGpAndPostCode} from "../graphql/queries";
import {bulkInvitePatients} from "../graphql/customMutations";

import {DATE_FORMAT, CONTROLLED_PATIENT_STATUS, DATE_TIME_FORMAT, NOTIFICATION_ALLOWED_ON_PATIENT_STATUS} from "../utils/constants";

import EnhancedTable from "../components/enhancedTable/fullPatientList";
import {controlledPatientBySource, controlledPatientByStatus, updateControlledPatientStatus} from "../services/controlledPatient";
import AppointmentBooking from "../components/AppointmentBooking.js";

const drawerWidth = 400;

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		backgroundColor: theme.palette.background.paper,
	},
	patientLink: {
		textDecoration: "none",
		color: "inherit",
		fontWeight: "500",
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	inline: {
		display: "inline",
	},
	avatar: {
		backgroundColor: theme.palette.primary.main, //
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: "flex",
		alignItems: "center",
		marginTop: theme.spacing(10),
		marginLeft: theme.spacing(2.5),
		marginRight: theme.spacing(2.5),
		marginBottom: theme.spacing(1),
		justifyContent: "space-between",
	},
	headerText: {
		fontSize: 20,
		fontWeight: "500",
	},
	drawerClose: {
		color: "#0fc3c5",
	},
	drawerContent: {
		padding: theme.spacing(3) - 4,
		display: "flex",
		flexDirection: "column",
		overflow: "auto",
		flex: 1,
	},
	surveyHeading: {
		display: "flex",
		alignItems: "center",
		paddingLeft: theme.spacing(2.5),
	},
	surveyMain: {
		fontSize: 20,
		marginRight: theme.spacing(1),
		fontWeight: "500",
		color: theme.palette.common.black,
	},
	sectionHeader: {
		fontSize: 12,
		color: "rgba(0, 0, 0, 0.87)",
	},
	surveySub: {
		fontWeight: "500",
		fontSize: 20,
		color: "rgba(0, 0, 0, 0.54)",
	},
	leftInput: {
		width: "100%",
		marginRight: theme.spacing(2),
	},
	disabledInput: {
		color: theme.palette.text.secondary, //
	},
	actScore: {
		width: theme.spacing(3),
		height: theme.spacing(3),
		fontSize: 14,
	},
	medicationList: {
		marginRight: theme.spacing(2),
		fontSize: 14,
		color: "rgba(0, 0, 0, 0.87)",
		letterSpacing: "0.15px",
		marginBottom: theme.spacing(1),
	},
	drawerFooter: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		// boxShadow: "0px -4px 12px #0000001a",
		paddingBottom: theme.spacing(1),
	},
	saveButton: {
		height: "42px",
		padding: "6px 20px",
		minWidth: "128px",
		fontSize: "15px",
		backgroundColor: "#0fc3c5",
		color: "white",
		borderRadius: "4px",
		margin: "16px 0px",
		textTransform: "none",
		"&:hover": {
			backgroundColor: "#0fc3c5",
		},
		"&:disabled": {
			backgroundColor: "rgba(0, 0, 0, 0.26)",
			color: "white",
		},
	},
	link: {
		color: "#0fc3c5",
		textDecoration: "none",
		textTransform: "none",
	},
	pageHeader: {
		fontWeight: "bold",
		paddingBottom: theme.spacing(0.5),
	},
	tab: {
		minWidth: "auto",
		width: "fit-content",
		padding: "0",
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2),
	},
	sourceSelect: {
		fontSize: "14px",
	},
	sourceMenu: {
		padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
	},
	paper: {
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	paperShift: {
		width: `calc(100% - ${drawerWidth - 68}px)`,
		minWidth: theme.spacing(92),
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: drawerWidth,
	},
	headingAcc: {
		display: "block",
		fontSize: theme.typography.pxToRem(14),
		fontWeight: theme.typography.fontWeightRegular,
	},
	secondaryAccHeading: {
		display: "block",
		fontSize: theme.typography.pxToRem(12),
		color: theme.palette.text.primary, //
	},
	accMoreInfo: {
		fontSize: theme.typography.pxToRem(12),
		color: theme.palette.text.primary, //
	},
	tabBar: {
		display: "flex",
		justifyContent: "space-between",
	},
	bulkNotify: {
		marginRight: theme.spacing(2),
		height: theme.spacing(5),
		minWidth: theme.spacing(14),
	},
}));

function TabPanel(props) {
	const {children, value, index, ...other} = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && <Box p={2}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const SURVEY_URL = `${window.location.origin}/#/survey`;

export default function ControlledPatientsPage(props) {
	const classes = useStyles();
	const {enqueueSnackbar} = useSnackbar();

	const [user, setUser] = useState(null);
	const [sourceList, setSourceList] = useState([]);

	const [selectedSource, setSelectedSource] = useState("");
	const [selectedPatient, setSelectedPatient] = useState(null);

	const [tabValue, setTabValue] = useState(0);
	const [loading, setLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [changeData, setChangeData] = useState(false);
	const [loadingMedications, setLoadingMedications] = useState(false);

	const [selectedPatients, setSelectedPatients] = useState([]);
	const [isBooked, setIsBooked] = useState(false);
	const [openNotifyDialog, setOpenNotifyDialog] = useState(false);
	const [locations, setLocations] = useState([]);

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	/**
	 *
	 * @param {[]} patients - controlled patient received from emis
	 * @returns {[]} formattedPatients
	 */
	const getFormattedPatients = patients => {
		return patients.map(controlledPatient => {
			const {patient, survey_response, gp_ods_code, nhs_number, gp_login, patient_guid, patient_ods_code} = controlledPatient;
			if (!patient) console.log(controlledPatient);
			let vaccinationRequested = false;
			if (survey_response) {
				const {receive_vaccination_1, receive_vaccination_2, receive_vaccination_3} = survey_response;
				vaccinationRequested = receive_vaccination_1 === "YES" || receive_vaccination_2 === "YES" || receive_vaccination_3 === "YES";
			}
			return {
				id: patient_guid,
				patName: patient ? `${patient.surname}, ${patient.given_name}` : "",
				status: controlledPatient.status,
				actScore: survey_response
					? survey_response.act_1 + survey_response.act_2 + survey_response.act_3 + survey_response.act_4 + survey_response.act_5
					: "",
				vaccine: survey_response ? (vaccinationRequested ? "Yes" : "No") : "",
				hospitalAdmissions: survey_response?.hospital_admission ? survey_response.hospital_admission : "",
				smokingStatus: survey_response ? survey_response.smoking_status : "",
				answeredOn: controlledPatient.survey_answered,
				gp_ods_code,
				nhs_number,
				gp_login,
				patient_ods_code,
				hash: controlledPatient.hash,
				control_level: controlledPatient.control_level,
				processed_response: controlledPatient.processed_response,
				survey: controlledPatient.survey,
				phone: patient.phone_number,
				dob: patient.birthdate,
				original_entry: controlledPatient,
			};
		});
	};

	useEffect(() => {
		if (!user) return;
		const getLocations = async () => {
			try {
				const result = await API.graphql(
					graphqlOperation(locationByGpAndPostCode, {
						gp_guid: user.attributes["custom:gp_guid"],
					})
				);

				console.log(result.data.locationByGPAndPostCode.items);
				setLocations(result.data.locationByGPAndPostCode.items);
			} catch (error) {
				console.log(error);
			} finally {
				// setIsLoading(false);
			}
		};
		getLocations();
	}, [user]);

	const queryAllControlledPatients = useCallback(
		async ({pageSize, currentToken, filter}) => {
			if (!selectedSource || !user) {
				return {
					data: [],
					nextToken: null,
				};
			}

			try {
				const result = await API.graphql(
					graphqlOperation(controlledPatientBySource, {
						source_id: selectedSource.source_id,
						gp_guid: {
							eq: user.attributes["custom:gp_guid"],
						},
						limit: pageSize,
						nextToken: currentToken,
						filter: filter,
					})
				);
				//parse
				const parsedList = result.data.controlledPatientBySource.items.map(entry => {
					entry.survey_response = JSON.parse(entry.survey_response);
					entry.processed_response = JSON.parse(entry.processed_response);
					return entry;
				});

				const nextToken = result.data.controlledPatientBySource.nextToken;
				return {
					data: getFormattedPatients(parsedList),
					nextToken,
				};
			} catch (err) {
				console.log(err);
				enqueueSnackbar(
					"There was an error while fetching the patient list. Please try again, if the problem persists, contanct support@medloop.co",
					{variant: "error"}
				);
				return {
					data: [],
					nextToken: null,
				};
			}
		},
		[enqueueSnackbar, selectedSource, user]
	);

	const queryControlledPatientByStatus = useCallback(
		async ({status, pageSize, currentToken}) => {
			if (!selectedSource || !user) {
				return {
					data: [],
					nextToken: null,
				};
			}
			try {
				const result = await API.graphql(
					graphqlOperation(controlledPatientByStatus, {
						gp_guidSource_id: {eq: {gp_guid: user.attributes["custom:gp_guid"], source_id: selectedSource.source_id}},
						status: status,
						limit: pageSize,
						nextToken: currentToken,
					})
				);

				const parsedList = result.data.controlledPatientByStatus.items.map(entry => {
					entry.survey_response = JSON.parse(entry.survey_response);
					entry.processed_response = JSON.parse(entry.processed_response);
					return entry;
				});
				const nextToken = result.data.controlledPatientByStatus.nextToken;
				return {
					data: getFormattedPatients(parsedList),
					nextToken,
				};
			} catch (err) {
				console.log(err);
				enqueueSnackbar(
					"There was an error while fetching the patient list. Please try again, Please try again, if the problem persists, contanct support@medloop.co",
					{variant: "error"}
				);
				return {
					data: [],
					nextToken: null,
				};
			}
		},
		[enqueueSnackbar, selectedSource, user]
	);

	const getNotfiedPatients = useCallback(
		async ({pageSize, currentToken}) => {
			//return await queryControlledPatientByStatus({pageSize, currentToken, status: CONTROLLED_PATIENT_STATUS.NOTIFIED});
			return await queryAllControlledPatients({
				pageSize: 200,
				currentToken,
				filter: {
					status: {
						eq: CONTROLLED_PATIENT_STATUS.NOTIFIED,
					},
				},
			});
		},
		[queryAllControlledPatients]
	);

	const getPatientsWithManualContact = useCallback(
		async ({pageSize, currentToken}) => {
			//return await queryControlledPatientByStatus({pageSize, currentToken, status: CONTROLLED_PATIENT_STATUS.NOTIFIED});
			return await queryAllControlledPatients({
				pageSize: 200,
				currentToken,
				filter: {
					status: {
						eq: CONTROLLED_PATIENT_STATUS.MANUAL_CONTACT,
					},
				},
			});
		},
		[queryAllControlledPatients]
	);

	const getPatientsWithControlLevel = useCallback(
		async ({pageSize, currentToken}) => {
			return await queryControlledPatientByStatus({pageSize, currentToken, status: CONTROLLED_PATIENT_STATUS.ACTION_REQUIRED});
		},
		[queryControlledPatientByStatus]
	);

	useEffect(() => {
		if (user) return;
		const getUser = async () => {
			try {
				setIsLoading(true);
				const _user = await Auth.currentAuthenticatedUser();
				setUser(_user);
			} catch (error) {
				console.log(error);
				enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
			} finally {
				setIsLoading(false);
			}
		};
		getUser();
	}, [enqueueSnackbar, user]);

	useEffect(() => {
		if (!user) return;
		const go = async () => {
			try {
				setIsLoading(true);
				console.log(user.attributes["custom:gp_guid"]);
				const result = await API.graphql(
					graphqlOperation(sourceByGp, {
						gp_guid: user.attributes["custom:gp_guid"],
						filter: {
							status: {
								eq: "PROCESSED",
							},
						},
					})
				);

				const list = result.data.sourceByGP.items;
				list.sort(function(a, b) {
					return moment(b.create_at) - moment(a.create_at);
				});

				setSourceList(list);
				setSelectedSource(list[0]);
			} catch (error) {
				console.log(error);
				enqueueSnackbar(
					"There was an error while fetching the source list. Please try again, Please try again, if the problem persists, contanct support@medloop.co",
					{variant: "error"}
				);
			} finally {
				setIsLoading(false);
			}
		};
		go();
	}, [enqueueSnackbar, user]);

	const handleChange = event => {
		setSelectedSource(event.target.value);
	};

	const loadSelectedPatient = useCallback(
		async patient => {
			setSelectedPatient(patient);
			setLoadingMedications(true);
			try {
				const result = await API.graphql(
					graphqlOperation(slotsByPatient, {
						patient_guid: patient.original_entry.patient_guid,
					})
				);
				patient.slots = result.data.slotsByPatient.items;
			} catch (error) {
				enqueueSnackbar("Error fetching medications patient data, please try again later.", {variant: "error"});
			} finally {
				setLoadingMedications(false);
			}
		},
		[enqueueSnackbar]
	);

	const getBookedSlots = () => {
		return selectedPatient.slots
			?.filter(e => e.status !== "CANCELLED")
			.map((slot, i) => {
				return (
					<Box key={i}>
						<Typography className={classes.headingAcc} variant="h5">
							{slot.day}/{slot.month}/{slot.year} - {slot.hour}:{slot.minute} | {slot.status}
						</Typography>
					</Box>
				);
			});
	};

	const isAlreadyBooked = () => {
		return (
			loadingMedications ||
			(selectedPatient && selectedPatient.slots && selectedPatient.slots.filter(e => e.status !== "CANCELLED").length >= 1)
		);
	};

	const handleNotifyPatient = useCallback(
		async patient => {
			setIsLoading(true);
			console.log(patient);
			const {patient_guid, source_id, gp_guid} = patient.original_entry;
			try {
				await API.graphql(
					graphqlOperation(bulkInvitePatients, {
						input: JSON.stringify([
							{
								patientGuid: patient_guid,
								sourceId: source_id,
								gpGuid: gp_guid,
								type: "INVITATION",
							},
						]),
					})
				);
				enqueueSnackbar("Patient will be notified.", {variant: "success"});
			} catch (error) {
				console.log(error);
				enqueueSnackbar("There was an error while notifying patient.", {variant: "error"});
			} finally {
				setIsLoading(false);
			}
		},
		[enqueueSnackbar]
	);

	const handleBulkNotify = async () => {
		try {
			let requestParams = selectedPatients.map(patient => {
				const {patient_guid, source_id, gp_guid} = patient.original_entry;
				return {
					patientGuid: patient_guid,
					sourceId: source_id,
					gpGuid: gp_guid,
					type: "INVITATION",
				};
			});
			await API.graphql(
				graphqlOperation(bulkInvitePatients, {
					input: JSON.stringify(requestParams),
				})
			);
			setChangeData(!changeData);
			setSelectedPatients([]);
			enqueueSnackbar(`${selectedPatients.length} patients will be notified.`, {variant: "success"});
		} catch (error) {
			console.log(error);
			enqueueSnackbar("There was an error while notifying the patients. Please try again", {variant: "error"});
		} finally {
			setLoading(false);
		}
	};

	const renderSourceItems = () => {
		if (sourceList.length > 0) {
			return sourceList.map(source => (
				<MenuItem key={source.id + source.name} value={source}>
					{decodeURIComponent(source.name) + " - " + moment(source.create_at).format("DD/MM/yyyy")}
				</MenuItem>
			));
		} else {
			return (
				<MenuItem key="None" value="None">
					{"Select a list source"}
				</MenuItem>
			);
		}
	};

	const columns = useMemo(
		() => [
			{
				Header: "Patient Name",
				accessor: "patName",
				numeric: false,
				disablePadding: false,
			},
			{
				Header: "Surgery Code",
				accessor: "patient_ods_code",
				numeric: false,
				disablePadding: false,
				size: "small",
				Cell: ({row}) => {
					return row.original.patient_ods_code ? row.original.patient_ods_code : row.original.gp_ods_code ? row.original.gp_ods_code : "";
				},
			},
			{
				Header: "Status",
				accessor: "status",
				numeric: false,
				disablePadding: false,
			},
			{
				Header: "NHS Number",
				accessor: "nhs_number",
				numeric: false,
				disablePadding: false,
			},
			{
				Header: "Consent",
				accessor: "vaccine",
				numeric: false,
				disablePadding: false,
			},

			{
				Header: "Response Date",
				accessor: "answeredOn",
				numeric: false,
				disablePadding: false,
				Cell: ({row}) => {
					return row.original.answeredOn ? moment(row.original.answeredOn).format(DATE_TIME_FORMAT) : "";
				},
			},
			{
				Header: "Mobile",
				accessor: "phone",
				numeric: false,
				disablePadding: false,
			},
			{
				Header: "Date of Birth",
				accessor: "dob",
				numeric: false,
				disablePadding: false,
			},
			{
				Header: "Booking Link",
				accessor: "link",
				numeric: false,
				disablePadding: true,
				size: "small",
				align: "center",
				Cell: ({row}) => {
					const {status} = row.original;
					const isDisabled = !NOTIFICATION_ALLOWED_ON_PATIENT_STATUS.includes(status);
					return (
						<Tooltip title="Copy Booking Link">
							<IconButton
								disabled={isDisabled}
								color="secondary" //
								aria-label="copy"
								onClick={() => {
									navigator.clipboard.writeText(`${SURVEY_URL}/${row.original.hash}`);
									enqueueSnackbar("Booking Link copied successfully.", {variant: "success"});
								}}
							>
								<FileCopyIcon />
							</IconButton>
						</Tooltip>
					);
				},
				disableSortBy: true,
			},
			{
				Header: "Action",
				accessor: "action",
				disableSortBy: true,
				numeric: false,
				disablePadding: true,
				size: "small",
				align: "center",
				Cell: ({row}) => {
					return (
						//
						<Tooltip title="Load Patient Details">
							<IconButton color="secondary" aria-label="load-patient-details" onClick={() => loadSelectedPatient(row.original)}>
								<LaunchIcon />
							</IconButton>
						</Tooltip>
					);
				},
			},
			{
				Header: "Nofity",
				accessor: "notify",
				numeric: false,
				disablePadding: true,
				size: "small",
				align: "center",
				Cell: ({row}) => {
					const {status} = row.original;
					const isDisabled = !NOTIFICATION_ALLOWED_ON_PATIENT_STATUS.includes(status);
					return (
						<Tooltip title="Notify Patient">
							<IconButton
								disabled={isDisabled}
								color="secondary" //
								aria-label="notify-patient"
								onClick={() => handleNotifyPatient(row.original)}
							>
								<SendIcon />
							</IconButton>
						</Tooltip>
					);
				},
				disableSortBy: true,
			},
		],
		[enqueueSnackbar, loadSelectedPatient, handleNotifyPatient]
	);

	// const getBookedView = () => {
	// 	return <Typography>Appointments Booked successfully</Typography>;
	// };

	const handleOpenNotifyDialog = () => {
		setOpenNotifyDialog(true);
	};

	const getSelectedPatientsCount = () => {
		if (selectedPatients) {
			return selectedPatients.length;
		}
		return "0";
	};

	return (
		<React.Fragment>
			<Box margin={2}>
				<Typography component="h5" variant="h5" className={classes.pageHeader}>
					Patient Manager
				</Typography>
				<FormControl>
					<Select
						id="demo-simple-select-outlined"
						value={sourceList.length > 0 ? selectedSource : "None"}
						onChange={handleChange}
						//disabled={selectedSource.length == 0}
						variant="outlined"
						classes={{
							root: classes.sourceSelect,
							select: classes.sourceMenu,
						}}
					>
						{renderSourceItems()}
					</Select>
				</FormControl>
			</Box>
			{isLoading && <LinearProgress variant="indeterminate" color="primary" />}
			<div className={classes.tabBar}>
				<Tabs value={tabValue} indicatorColor="primary" textColor="primary" onChange={handleTabChange} aria-label="patient-tabs">
					<Tab
						className={classes.tab}
						label={selectedSource ? `Full Patient List(${selectedSource?.controlled_patient_count})` : `Full Patient List`}
						{...a11yProps(0)}
					/>
					<Tab className={classes.tab} label="Manual Contact" {...a11yProps(1)} />
					<Tab className={classes.tab} label="Patient Notification Status" {...a11yProps(2)} />
					<Tab className={classes.tab} label="Patient Responses" {...a11yProps(3)} />
				</Tabs>
				{tabValue !== 2 ? (
					<Button
						variant="contained"
						color="secondary"
						disabled={!(selectedPatients && selectedPatients.length)}
						className={classes.bulkNotify}
						startIcon={<SendIcon />}
						onClick={handleOpenNotifyDialog}
					>
						Notify selected
					</Button>
				) : (
					<div></div>
				)}
				<ConfirmDialog
					title={"Notify selected patients"}
					content={"You are about to send sms invitation to " + getSelectedPatientsCount() + " patient(s), Are you sure?"}
					open={openNotifyDialog}
					setOpen={setOpenNotifyDialog}
					onConfirm={handleBulkNotify}
				/>
			</div>

			<TabPanel style={{padding: "0px"}} value={tabValue} index={0}>
				<EnhancedTable
					selectable={true}
					resetData={changeData}
					setSelectedRows={setSelectedPatients}
					columns={columns}
					fetchRecords={queryAllControlledPatients}
					enqueueSnackbar={enqueueSnackbar}
				/>
			</TabPanel>

			<TabPanel value={tabValue} index={1}>
				<EnhancedTable columns={columns} fetchRecords={getPatientsWithManualContact} />
			</TabPanel>

			<TabPanel value={tabValue} index={2}>
				{/* <Toolbar className={classes.root}>
						<Typography className={classes.title} color="inherit" variant="h6" component="div" gutterBottom>
							All the patients who have been notified either by SMS or Email.
						</Typography>
					</Toolbar> */}
				<EnhancedTable
					selectable={true}
					resetData={changeData}
					enqueueSnackbar={enqueueSnackbar}
					setSelectedRows={setSelectedPatients}
					columns={columns}
					fetchRecords={getNotfiedPatients}
				/>
			</TabPanel>

			<TabPanel value={tabValue} index={3}>
				<EnhancedTable columns={columns} fetchRecords={getPatientsWithControlLevel} />
			</TabPanel>

			{selectedPatient && (
				<Drawer
					className={classes.drawer}
					variant="persistent"
					anchor="right"
					open={Boolean(selectedPatient)}
					classes={{
						paper: classes.drawerPaper,
					}}
				>
					<div className={classes.drawerHeader}>
						<Typography className={classes.headerText}>{selectedPatient.patName}</Typography>
						<IconButton className={classes.drawerClose} onClick={async () => setSelectedPatient(null)}>
							<CloseIcon />
						</IconButton>
					</div>
					<Typography className={classes.surveyHeading}>
						<Box className={classes.surveyMain}>{selectedPatient?.survey?.name + ","}</Box>
						<Box className={classes.surveySub}>{Moment(new Date(selectedPatient?.answeredOn).toUTCString()).format(DATE_FORMAT)}</Box>
					</Typography>
					<Box className={classes.drawerContent}>
						{isAlreadyBooked() ? (
							<div></div>
						) : (
							<>
								<Box mb={0} className={classes.sectionHeader}>
									Appointments
								</Box>
								{/* {isBooked ? (
									getBookedView()
								) : ( */}
								<AppointmentBooking
									setIsLoading={setLoading}
									bookedSlots={selectedPatient?.slots}
									mobile={selectedPatient?.phone}
									dob={selectedPatient?.dob}
									hash={selectedPatient?.hash}
									onBookingComplete={async slots => {
										const {patient_guid, source_id, gp_guid} = selectedPatient.original_entry;
										await API.graphql(
											graphqlOperation(updateControlledPatientStatus, {
												input: {
													source_id,
													patient_guid,
													gp_guid,
													survey_answered: new Date(),
													survey_response: JSON.stringify({
														ProxySelf: "manual_appointment",
														patient_consent: ["consent_1", "consent_3", "consent_4"],
														dob: selectedPatient?.dob,
														receive_vaccination_1: "YES",
													}),
													status: "ANSWERED",
												},
											})
										);
										selectedPatient.slots = slots;
										let _source = selectedSource;
										setSelectedSource({..._source});
										setSelectedPatient(null);
										enqueueSnackbar("Appointment successfully booked!", {variant: "success"});
									}}
									locations={locations}
									vertical={true}
								/>
								{/* )} */}
							</>
						)}
						{console.table(selectedPatient.slots)}
						{selectedPatient?.slots && selectedPatient.slots.filter(e => e.status !== "CANCELLED").length >= 1 ? (
							<Box mt={4} mb={2} className={classes.sectionHeader}>
								Already scheduled appointments
							</Box>
						) : (
							""
						)}
						<Typography>{getBookedSlots()}</Typography>
						{/* <Box mt={4} mb={2} className={classes.sectionHeader}>
							MEDICATIONS
						</Box>
						<Typography>{getMedicationsList()}</Typography> */}
					</Box>

					{/* <Box className={classes.drawerFooter}>
						<Button
							disabled={selectedPatient.status !== "ACTION_REQUIRED"}
							className={classes.saveButton}
							onClick={() => saveAndFileTOEmis(selectedPatient)}
						>
							Save and file to EMIS
						</Button>
					</Box> */}
				</Drawer>
			)}
			{/* </Paper> */}
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</React.Fragment>
	);
}

import DateFnsUtils from "@date-io/date-fns";
import {Box, Button, CircularProgress, Grid, Select, MenuItem, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {API} from "aws-amplify";
import React, {useState, useEffect} from "react";
import "survey-react/survey.css";
import {getDateFormatted} from "../utils/utils.js";
import * as moment from "moment";
import LocationOnIcon from "@material-ui/icons/LocationOn";

const useStyles = makeStyles(theme => ({
	bookingWrapper: {
		display: "flex",
	},
	bookingRow: {
		display: "flex",
		justifyContent: "space-between",
		padding: theme.spacing(1.5, 0),
	},
	slots: {
		flex: 1,
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
	},
	appoinmentHeader: {
		display: "flex",
		flexDirection: "column",
		marginTop: theme.spacing(2),
	},
	appoinmentinfo: {
		paddingBottom: theme.spacing(1),
	},
}));

export default function AppointmentBooking(props) {
	const classes = useStyles();
	let date = new Date();
	const [slotDate, setSlotDate] = useState(date);
	const [secondSlotDate, setSecondSlotDate] = useState(date);
	const [loadingSlots, setLoadingSlots] = useState(false);
	const [slots, setSlots] = useState([]);

	const [selectedSlot, setSelectedSlot] = useState(null);
	const {mobile, dob, hash, onBookingComplete, setIsLoading, enqueueSnackbar, locations} = props;
	const [location, setLocation] = useState(locations[0]);

	useEffect(() => {
		const date = getDateFormatted(slotDate);
		if (!location || !location.staff_id) return;
		const getSlots = async () => {
			try {
				setLoadingSlots(true);
				const requestParams = {
					queryStringParameters: {
						date: date,
						location: location.staff_id,
					},
				};
				let response = await API.get("appointments", `/slots/${hash}`, requestParams);
				setSlots(response.slots);
			} catch (error) {
				console.log(error);
			} finally {
				setLoadingSlots(false);
			}
		};
		getSlots();
	}, [slotDate, location.staff_id, location, hash]);

	const bookAppointment = async () => {
		setIsLoading(true);
		const requestParams = {
			body: {
				phone: mobile,
				birthdate: dob,
				//slots: [selectedSlot, selectedSecondSlot],
				slots: [selectedSlot],
				parent_slot: selectedSlot,
			},
		};
		try {
			await API.post("appointments", `/slots/${hash}`, requestParams);
			if (enqueueSnackbar) {
				enqueueSnackbar("Successfully booked an appointment", {variant: "success"});
			}
			await onBookingComplete(requestParams.body.slots);
		} catch (error) {
			if (enqueueSnackbar) {
				enqueueSnackbar("Error in booking appointment", {variant: "error"});
			}
		} finally {
			setIsLoading(false);
		}
		console.log(requestParams);
	};

	const validationCheck = date => {
		if (moment(secondSlotDate).diff(moment(date), "days") < 28) {
			date.setDate(date.getDate() + 28);
			setSecondSlotDate(date);
		}
	};

	const handleSlotClick = (slot, type) => {
		console.log("Slot: " + JSON.stringify(slot), slot);
		if (type === "first") {
			setSelectedSlot(slot);
			// if (selectedSecondSlot && selectedSecondSlot.id === slot.id) {
			// 	setSelectedSecondSlot(null);
			// }
			let date = new Date(slotDate);
			// setSecondSlotsFetched(true);
			validationCheck(date);
		} else if (type === "second") {
			//setSelectedSecondSlot(slot);
		}
	};

	const handleSlotDateChange = async (value, type) => {
		if (type === "first") {
			if (
				value.getFullYear() === slotDate.getFullYear() &&
				value.getMonth() === slotDate.getMonth() &&
				value.getDate() === slotDate.getDate()
			)
				return;
			setSelectedSlot(null);
			// setSelectedSecondSlot(null);
			// setSecondSlotsFetched(false);
			setSlotDate(value);
			let date = new Date(value);
			validationCheck(date);
		} else if (type === "second") {
		}
	};

	const handleChangeLocation = (event, type) => {
		if (type === "first") {
			setLocation(event.target.value);
			setSlots([]);
		} else if (type === "second") {
		}
	};

	const bookAppointmentPanel = () => {
		return (
			<Grid spacing={2} container justify="center" className={classes.bookingWrapper}>
				<Grid item xs={12} md={12}>
					<Select
						fullWidth
						margin="dense"
						variant="outlined"
						IconComponent={() => <LocationOnIcon />}
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={location}
						onChange={e => handleChangeLocation(e, "first")}
					>
						{locations.map(location => (
							<MenuItem key={location.staff_id} value={location}>
								{location.name}
							</MenuItem>
						))}
					</Select>
				</Grid>
				<Grid item xs={12} md={12}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							// disableToolbar
							fullWidth
							allowKeyboardControl={true}
							inputVariant="outlined"
							format="MM-dd-yyyy"
							margin="dense"
							id="date-picker-inline"
							value={slotDate}
							onChange={e => handleSlotDateChange(e, "first")}
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
							minDate={Date.now()}
							autoOk
						/>
					</MuiPickersUtilsProvider>
				</Grid>
				<Grid item xs={12} md={12}>
					<Grid container spacing={2}>
						{loadingSlots ? (
							<Box style={{width: "100%"}} display="flex" justifyContent="center">
								<CircularProgress color="inherit" />
							</Box>
						) : slots.length > 0 ? (
							slots.map(slot => (
								<Grid item xs={6} md={4}>
									<Button
										fullWidth
										disabled={selectedSlot?.id === slot.id}
										variant="contained"
										size="medium"
										onClick={() => handleSlotClick(slot, "first")}
									>{`${slot.hour}:${slot.minute}`}</Button>
								</Grid>
							))
						) : (
							<Box style={{width: "100%"}} display="flex" justifyContent="center">
								<Typography align="center" component="div">
									<Box fontSize={20} color="textPrimary">
										{`No Slots Available on: ${slotDate.toLocaleDateString()}`}
									</Box>
								</Typography>
							</Box>
						)}
					</Grid>
				</Grid>
				{selectedSlot ? (
					<Grid item xs={12} md={12}>
						<Button fullWidth onClick={bookAppointment} variant="contained" color="primary">
							Book Covid Appointment
						</Button>
					</Grid>
				) : null}
			</Grid>
		);
	};

	return bookAppointmentPanel();
}

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:c90e7e0f-357e-4a21-bd7a-dc00b4d5d5b8",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_nDw2c02nH",
    "aws_user_pools_web_client_id": "isq5mfttn07pqti60t1dgv0p2",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://vzkx525y4va6np33f62yrki25e.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "surveys",
            "endpoint": "https://dkcuf3ovle.execute-api.eu-west-2.amazonaws.com/covid",
            "region": "eu-west-2"
        },
        {
            "name": "pimi",
            "endpoint": "https://2hrsb09z48.execute-api.eu-west-2.amazonaws.com/covid",
            "region": "eu-west-2"
        },
        {
            "name": "appointments",
            "endpoint": "https://y4h5k5hltc.execute-api.eu-west-2.amazonaws.com/covid",
            "region": "eu-west-2"
        }
    ],
    "aws_content_delivery_bucket": "medloop-eoraptor-covid",
    "aws_content_delivery_bucket_region": "eu-west-2",
    "aws_content_delivery_url": "http://medloop-eoraptor-covid.s3-website.eu-west-2.amazonaws.com",
    "aws_user_files_s3_bucket": "medloop-eoraptor-emis-reportscovid-covid",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;

import DateFnsUtils from "@date-io/date-fns";
import {Box, Button, Chip, Grid, CircularProgress, MenuItem, Select, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import {API, graphqlOperation, Auth} from "aws-amplify";
import moment from "moment";
import React, {useCallback, useEffect, useState} from "react";
import "survey-react/survey.css";
import {locationByGpAndPostCode, slotsByPatient} from "../graphql/queries";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {getDateFormatted} from "../utils/utils.js";
import Checkbox from "@material-ui/core/Checkbox";
import {updateSlots} from "../graphql/mutations";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
	root: {
		minWidth: theme.spacing(75),
	},
	bookingWrapper: {
		display: "flex",
		flexDirection: "column",
	},
	bookingRow: {
		display: "flex",
		justifyContent: "space-between",
		padding: theme.spacing(1.5, 0),
	},
	completedRow: {
		display: "flex",
		padding: theme.spacing(1.5, 0),
	},
	completedText: {
		fontSize: 20,
	},
	completedDate: {
		fontSize: 20,
		padding: theme.spacing(0, 1.5),
	},
	modifyCheck: {
		display: "flex",
		padding: theme.spacing(0.5, 0),
		alignItems: "center",
	},
	slots_wrapper: {
		margin: theme.spacing(2, 0),
	},
	slots: {
		flex: 1,
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		maxHeight: theme.spacing(15),
		overflow: "auto",
		alignItems: "center",
		minHeight: theme.spacing(7),
		paddingLeft: theme.spacing(1.5),
	},
	appoinmentHeader: {
		display: "flex",
		flexDirection: "column",
		marginTop: theme.spacing(2),
	},
	appoinmentinfo: {
		paddingBottom: theme.spacing(1),
	},
	spinner: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		minHeight: theme.spacing(16),
	},
}));

export default function ModifyBookings(props) {
	const classes = useStyles();
	const {patient_ids, user, handleClose, setLoadingBackdrop, refreshEvents} = props;
	const [firstAppointment, setFirstAppointment] = useState({date: null, slot: null, newSlot: null, isCompleted: false});
	const [secondAppointment, setSecondAppointment] = useState({date: null, slot: null, newSlot: null, isCompleted: false});
	const [loading, setLoading] = useState(false);
	const [locations, setLocations] = useState([]);
	const [location, setLocation] = useState(null);
	const [loadingFirstSlots, setLoadingFirstSlots] = useState(false);
	const [loadingSecondSlots, setLoadingSecondSlots] = useState(false);
	const [firstSlots, setFirstSlots] = useState([]);
	const [secondSlots, setSecondSlots] = useState([]);
	const [isNotBooked, setIsNotBooked] = useState(false);
	const [modifyChecks, setModifyChecks] = useState({
		first: false,
		second: false,
	});
	const [isSecondDisabled, setIsSecondDIsabled] = useState(false);
	const [isSaveDisabled, setIsSaveDisabled] = useState(true);
	const {enqueueSnackbar} = useSnackbar();

	useEffect(() => {
		setLoading(true);
		const getLocations = async () => {
			try {
				const result = await API.graphql(
					graphqlOperation(locationByGpAndPostCode, {
						gp_guid: user.attributes["custom:gp_guid"],
					})
				);

				console.log(result.data.locationByGPAndPostCode.items);
				setLocations(result.data.locationByGPAndPostCode.items);
				setLocation(result.data.locationByGPAndPostCode.items[0]);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};
		getLocations();
	}, [user.attributes]);

	const setBookings = useCallback(bookings => {
		if (!bookings || bookings.length < 1) return;
		if (bookings.length === 1) {
			const firstDate = getDate(bookings[0]);
			setFirstAppointment({
				date: firstDate,
				slot: bookings[0],
				isCompleted: isCompleted(firstDate),
			});
			setIsNotBooked(true);
		} else {
			const firstDate = getDate(bookings[0]);
			const secondDate = getDate(bookings[1]);
			if (secondDate - firstDate > 0) {
				setFirstAppointment({
					date: firstDate,
					slot: bookings[0],
					isCompleted: isCompleted(firstDate),
				});
				setSecondAppointment({
					date: secondDate,
					slot: bookings[1],
					isCompleted: isCompleted(secondDate),
				});
			} else {
				setFirstAppointment({
					date: secondDate,
					slot: bookings[1],
					isCompleted: isCompleted(secondDate),
				});
				setSecondAppointment({
					date: firstDate,
					slot: bookings[0],
					isCompleted: isCompleted(firstDate),
				});
			}
		}
	}, []);

	useEffect(() => {
		setLoading(true);
		const getSlotsByPatient = async () => {
			try {
				const result = await API.graphql(
					graphqlOperation(slotsByPatient, {
						patient_guid: patient_ids.patient_guid,
					})
				);

				setBookings(result.data.slotsByPatient.items);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};
		getSlotsByPatient();
	}, [patient_ids, setBookings]);

	useEffect(() => {
		if (!firstAppointment.date || !location || !location.staff_id || !patient_ids) return;
		const date = getDateFormatted(firstAppointment.date);
		const getSlots = async () => {
			try {
				setLoadingFirstSlots(true);
				const requestParams = {
					queryStringParameters: {
						date: date,
						location: location.staff_id,
					},
				};
				let response = await API.get("appointments", `/slots/${patient_ids.patient_hash}`, requestParams);
				setFirstSlots(response.slots);
			} catch (error) {
				console.log(error);
			} finally {
				setLoadingFirstSlots(false);
			}
		};
		getSlots();
	}, [firstAppointment.date, patient_ids, location]);

	useEffect(() => {
		if (!secondAppointment.date || !location || !location.staff_id || !patient_ids) return;
		const date = getDateFormatted(secondAppointment.date);
		const getSlots = async () => {
			try {
				setLoadingSecondSlots(true);
				const requestParams = {
					queryStringParameters: {
						date: date,
						location: location.staff_id,
					},
				};
				let response = await API.get("appointments", `/slots/${patient_ids.patient_hash}`, requestParams);
				setSecondSlots(response.slots);
			} catch (error) {
				console.log(error);
			} finally {
				setLoadingSecondSlots(false);
			}
		};
		getSlots();
	}, [secondAppointment.date, patient_ids, location]);

	const handleChangeLocation = event => {
		setLocation(event.target.value);
		// setSlots([]);
		//TODO update slots.
	};

	const getDate = booking => {
		return new Date(booking.year + "-" + booking.month + "-" + booking.day + "T" + booking.hour + ":" + booking.minute);
	};

	const isCompleted = date => {
		return moment().diff(moment(date)) > 0;
	};

	const handleFirstSlotDateChange = async value => {
		if (
			value.getFullYear() === firstAppointment.date.getFullYear() &&
			value.getMonth() === firstAppointment.date.getMonth() &&
			value.getDate() === firstAppointment.date.getDate()
		)
			return;

		if (Math.round((secondAppointment.date - value) / (1000 * 60 * 60 * 24)) < 28) {
			const minDate = getMinDate(value);
			setSecondAppointment({...secondAppointment, date: minDate, newSlot: null});
		}

		if (Math.round((getDate(secondAppointment.slot) - value) / (1000 * 60 * 60 * 24) < 28)) {
			setModifyChecks({...modifyChecks, second: false});
			setIsSecondDIsabled(true);
		} else {
			setIsSecondDIsabled(false);
		}

		setFirstAppointment({...firstAppointment, date: value});
	};

	const handleSecondSlotDateChange = async value => {
		if (
			value.getFullYear() === secondAppointment.date.getFullYear() &&
			value.getMonth() === secondAppointment.date.getMonth() &&
			value.getDate() === secondAppointment.date.getDate()
		)
			return;

		setSecondAppointment({...secondAppointment, date: value});
	};

	const handleFirstSlotClick = slot => {
		setFirstAppointment({...firstAppointment, newSlot: slot});
	};

	const handleSecondSlotClick = slot => {
		setSecondAppointment({...secondAppointment, newSlot: slot});
	};

	const getMinDate = value => {
		if (!value) return;
		const min = new Date(value);
		min.setDate(min.getDate() + 28);
		return min;
	};

	const getAppointmentString = appointment => {
		if (!appointment || !appointment.date) return "";
		const dateString =
			("0" + (appointment.date.getMonth() + 1)).slice(-2) +
			"-" +
			("0" + appointment.date.getDate()).slice(-2) +
			"-" +
			appointment.date.getFullYear();
		return `${dateString} - ${appointment.slot.hour}:${appointment.slot.minute}`;
	};

	const handleModifyCheck = (key, event) => {
		setModifyChecks({...modifyChecks, [key]: event.target.checked});
	};

	useEffect(() => {
		if (!firstAppointment || !secondAppointment) return;
		const isDisabled = () => {
			if (firstAppointment.isCompleted && secondAppointment.isCompleted) return true;
			if (isNotBooked) {
				if (modifyChecks.first || !firstAppointment.newSlot) return true;
			} else if (firstAppointment.isCompleted) {
				if (modifyChecks.second || !secondAppointment.newSlot) return true;
			} else {
				if (modifyChecks.first && modifyChecks.second) {
					return true;
				}
				if (!modifyChecks.first && !firstAppointment.newSlot) {
					return true;
				}
				if (!modifyChecks.second && !secondAppointment.newSlot) {
					return true;
				}
			}
			return false;
		};
		setIsSaveDisabled(isDisabled());
	}, [firstAppointment, modifyChecks.first, modifyChecks.second, secondAppointment, isNotBooked]);

	const handleCancelAppointments = async () => {
		setLoadingBackdrop(true);
		let success = true;
		let messageString = "";
		try {
			let patient_detail = JSON.parse(patient_ids?.reason);
			messageString += `Hello ${patient_detail.patient_name}, we're sorry to inform that your covid vaccination appointments on: `;
			if (!firstAppointment.isCompleted) {
				const firstSlot = firstAppointment.slot;
				const initial_status = firstSlot.status;
				firstSlot.status = "CANCELLED";
				firstSlot.staff_id = firstSlot.location.staff_id;
				delete firstSlot.location;
				delete firstSlot.parent;
				await API.graphql(
					graphqlOperation(updateSlots, {
						input: firstSlot,
						...(initial_status && {condition: {status: {eq: initial_status}}}),
					})
				);
				messageString += `${firstAppointment.slot.day}/${firstAppointment.slot.month}/${firstAppointment.slot.year} and `;
			}
			success = true;
		} catch (err) {
			console.log(err);
			enqueueSnackbar("Error cancelling appointments", {variant: "error"});
			success = false;
		}
		if (success) {
			try {
				if (!isNotBooked && !secondAppointment.isCompleted) {
					const secondSlot = secondAppointment.slot;
					const initial_status = secondSlot.status;
					secondSlot.status = "CANCELLED";
					secondSlot.staff_id = secondSlot.location.staff_id;
					delete secondSlot.location;
					delete secondSlot.parent;
					await API.graphql(
						graphqlOperation(updateSlots, {
							input: secondSlot,
							...(initial_status && {condition: {status: {eq: initial_status}}}),
						})
					);
					messageString += `${secondAppointment.slot.day}/${secondAppointment.slot.month}/${secondAppointment.slot.year} `;
				}
				let values = {
					message: messageString + `got cancelled, please contact us on: xxxx for more information.`,
				};
				enqueueSnackbar("sucessfully cancelled appointments.", {variant: "success"});
				await sendNotif(firstAppointment.slot, values);
				handleClose();
				refreshEvents();
			} catch (err) {
				console.log(err);
				if (!firstAppointment.isCompleted) {
					revertFirstCancelled();
				}
				enqueueSnackbar("Error cancelling appointments", {variant: "error"});
			} finally {
				setLoadingBackdrop(false);
			}
		}
	};

	const revertFirstCancelled = async () => {
		try {
			if (!firstAppointment.isCompleted) {
				const firstSlot = firstAppointment.slot;
				const initial_status = firstSlot.status;
				firstSlot.status = "BOOKED";
				await API.graphql(
					graphqlOperation(updateSlots, {
						input: firstSlot,
						...(initial_status && {condition: {status: {eq: initial_status}}}),
					})
				);
			}
		} catch (err) {
			console.log(err);
			enqueueSnackbar("Error cancelling appointments", {variant: "error"});
		}
	};

	const sendNotif = async (slot, values) => {
		try {
			const notificationAPI = `https://stg-apigateway.medloop.uk/notify/sms`;
			await fetch(notificationAPI, {
				method: "post",
				headers: {
					Authorization: (await Auth.currentSession()).getIdToken().jwtToken,
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				body: JSON.stringify({
					phoneNumber: JSON.parse(patient_ids.reason).patient_mobile,
					message: `${values.message}`,
				}),
			});
			enqueueSnackbar("SMS sucessfully sent.", {variant: "success"});
		} catch (err) {
			console.log(`ERROR: ${err}`);
			enqueueSnackbar("Error sending SMS", {variant: "error"});
		}
	};

	const hanldeModifyAppointments = async () => {
		setLoadingBackdrop(true);
		let success = true;
		let patient_detail = JSON.parse(patient_ids.reason);
		let messageString = `Hello ${patient_detail.patient_name}, Your covid-19 vaccination information is updated, new dates are: `;
		if (!firstAppointment.isCompleted && firstAppointment.newSlot) {
			try {
				const firstSlot = firstAppointment.slot;
				const initial_status = firstSlot.status;
				firstSlot.status = "AVAILABLE";
				firstSlot.staff_id = firstSlot.location.staff_id;
				delete firstSlot.location;
				firstSlot.patient_guid = null;
				firstSlot.patient_hash = null;
				delete firstSlot.comments;
				delete firstSlot.reason;
				delete firstSlot.patient_ods_code;
				delete firstSlot.title;
				delete firstSlot.parent;
				await API.graphql(
					graphqlOperation(updateSlots, {
						input: firstSlot,
						...(initial_status && {condition: {status: {eq: initial_status}}}),
					})
				);
			} catch (err) {
				enqueueSnackbar("Error modifying appointments", {variant: "error"});
				console.log(err);
				success = false;
			}
			if (success) {
				try {
					const firstNewSlot = firstAppointment.newSlot;
					const initial_status2 = firstNewSlot.status;
					firstNewSlot.status = "BOOKED";
					firstNewSlot.staff_id = location.staff_id;
					firstNewSlot.patient_guid = patient_ids.patient_guid;
					firstNewSlot.patient_hash = patient_ids.patient_hash;
					firstNewSlot.reason = patient_ids.reason;
					firstNewSlot.title = patient_ids.patient_title;
					delete firstNewSlot.__typename;
					delete firstNewSlot.parent;
					await API.graphql(
						graphqlOperation(updateSlots, {
							input: firstNewSlot,
							...(initial_status2 && {condition: {status: {eq: initial_status2}}}),
						})
					);
					messageString += `${firstNewSlot.day}/${firstNewSlot.month}/${firstNewSlot.year} and `;
				} catch (err) {
					enqueueSnackbar("Error modifying appointments", {variant: "error"});
					console.log(err);
					revertAppointmentToBooked(firstAppointment.slot);
					success = false;
				}
			}
		}
		if (success) {
			try {
				if (!isNotBooked && !secondAppointment.isCompleted && secondAppointment.newSlot) {
					const secondSlot = secondAppointment.slot;
					const initial_status = secondSlot.status;
					secondSlot.status = "AVAILABLE";
					secondSlot.staff_id = secondSlot.location.staff_id;
					delete secondSlot.location;
					secondSlot.patient_guid = null;
					secondSlot.patient_hash = null;
					secondSlot.parent_slot = null;
					delete secondSlot.comments;
					delete secondSlot.reason;
					delete secondSlot.patient_ods_code;
					delete secondSlot.title;
					delete secondSlot.parent;
					await API.graphql(
						graphqlOperation(updateSlots, {
							input: secondSlot,
							...(initial_status && {condition: {status: {eq: initial_status}}}),
						})
					);
				}
			} catch (err) {
				enqueueSnackbar("Error modifying appointments", {variant: "error"});
				console.log(err);
				revertAppointmentToBooked(firstAppointment.slot);
				revertAppointmentToAvailable(firstAppointment.newSlot);
				success = false;
			}
		}
		if (success) {
			try {
				if (!isNotBooked && !secondAppointment.isCompleted && secondAppointment.newSlot) {
					const secondNewSlot = secondAppointment.newSlot;
					const initial_status2 = secondNewSlot.status;
					secondNewSlot.status = "BOOKED";
					secondNewSlot.parent_slot = firstAppointment.newSlot.id;
					secondNewSlot.staff_id = location.staff_id;
					secondNewSlot.patient_guid = patient_ids.patient_guid;
					secondNewSlot.patient_hash = patient_ids.patient_hash;
					secondNewSlot.reason = patient_ids.reason;
					secondNewSlot.title = patient_ids.patient_title;
					delete secondNewSlot.__typename;
					delete secondNewSlot.parent;
					await API.graphql(
						graphqlOperation(updateSlots, {
							input: secondNewSlot,
							...(initial_status2 && {condition: {status: {eq: initial_status2}}}),
						})
					);
					messageString += `${secondNewSlot.day}/${secondNewSlot.month}/${secondNewSlot.year} `;
				}
				let values = {
					message: messageString + `got cancelled, please contact us on: xxxx for more information.`,
				};
				enqueueSnackbar("sucessfully modified appointments.", {variant: "success"});
				await sendNotif(firstAppointment.slot, values);
				refreshEvents();
				handleClose();
			} catch (err) {
				enqueueSnackbar("Error modifying appointments", {variant: "error"});
				revertAppointmentToBooked(firstAppointment.slot);
				revertAppointmentToAvailable(firstAppointment.newSlot);
				revertAppointmentToBooked(secondAppointment.slot, firstAppointment.slot.id);
				console.log(err);
			}
		}
		setLoadingBackdrop(false);
	};

	const revertAppointmentToBooked = async (slot, {parentSlot}) => {
		try {
			const initial_status = slot.status;
			slot.status = "BOOKED";
			slot.patient_guid = patient_ids.patient_guid;
			slot.patient_hash = patient_ids.patient_hash;
			slot.parent_slot = parentSlot ? parentSlot : null;
			slot.staff_id = location.staff_id;
			slot.reason = patient_ids.reason;
			slot.title = patient_ids.patient_title;
			delete slot.__typename;
			await API.graphql(
				graphqlOperation(updateSlots, {
					input: slot,
					...(initial_status && {condition: {status: {eq: initial_status}}}),
				})
			);
		} catch (err) {
			console.log(err);
			enqueueSnackbar("Error cancelling appointments", {variant: "error"});
		}
	};

	const revertAppointmentToAvailable = async slot => {
		try {
			const initial_status = slot.status;
			slot.status = "AVAILABLE";
			slot.patient_guid = null;
			slot.patient_hash = null;
			slot.paretnSlot = null;
			slot.staff_id = slot.location.staff_id;
			delete slot.location;
			delete slot.comments;
			delete slot.reason;
			delete slot.patient_ods_code;
			delete slot.title;
			await API.graphql(
				graphqlOperation(updateSlots, {
					input: slot,
					...(initial_status && {condition: {status: {eq: initial_status}}}),
				})
			);
		} catch (err) {
			console.log(err);
			enqueueSnackbar("Error cancelling appointments", {variant: "error"});
		}
	};

	const panel = () => {
		let patient_detail = patient_ids ? JSON.parse(patient_ids.reason) : "";
		return (
			<div className={classes.root}>
				<DialogTitle>{`Modify booking for ${patient_detail.patient_name} ${patient_detail.patient_surname}`}</DialogTitle>
				<DialogContent>
					<Typography>Locations</Typography>
					<Grid item>
						<Select
							fullWidth
							margin="dense"
							variant="outlined"
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={location}
							onChange={handleChangeLocation}
						>
							{locations.map(location => (
								<MenuItem key={location.staff_id} value={location}>
									{location.name}
								</MenuItem>
							))}
						</Select>
					</Grid>
					{loading ? (
						<Grid className={classes.spinner}>
							<CircularProgress color="inherit" />
						</Grid>
					) : (
						<Grid className={classes.slots_wrapper}>
							{firstAppointment && firstAppointment.date && firstAppointment.isCompleted ? (
								<Grid container className={classes.completedRow} alignItems={"center"} direction={"row"}>
									<Typography className={classes.completedText} color={"secondary"}>
										First shot:
									</Typography>
									<Typography className={classes.completedDate} color={"secondary"}>
										{getAppointmentString(firstAppointment)}
									</Typography>
									<Typography className={classes.completedText} color={"primary"}>
										Completed
									</Typography>
								</Grid>
							) : (
								<div></div>
							)}
							{firstAppointment && !firstAppointment.isCompleted ? (
								<Grid>
									<Grid container className={classes.bookingRow} alignItems={"center"} direction={"row"}>
										<Grid item>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<KeyboardDatePicker
													inputVariant="outlined"
													label="First shot"
													allowKeyboardControl={true}
													variant="inline"
													format="MM-dd-yyyy"
													margin="normal"
													id="date-picker-inline"
													value={firstAppointment.date}
													disabled={modifyChecks.first}
													onChange={handleFirstSlotDateChange}
													KeyboardButtonProps={{
														"aria-label": "change date",
													}}
													autoOk
													minDate={new Date()}
												/>
											</MuiPickersUtilsProvider>
										</Grid>
										<Grid item className={classes.slots}>
											{loadingFirstSlots ? (
												<CircularProgress color="inherit" />
											) : firstSlots.length > 0 ? (
												firstSlots.map(slot => (
													<Box m={0.5} key={`${slot.id}`}>
														<Chip
															disabled={modifyChecks.first || firstAppointment.newSlot?.id === slot.id}
															label={`${slot.hour}:${slot.minute}`}
															onClick={() => handleFirstSlotClick(slot)}
														/>
													</Box>
												))
											) : (
												`No Slots Available on ${firstAppointment.date}`
											)}
										</Grid>
									</Grid>
									<Grid className={classes.modifyCheck}>
										<Checkbox
											checked={modifyChecks.first}
											onChange={event => handleModifyCheck("first", event)}
											inputProps={{"aria-label": "primary checkbox"}}
										></Checkbox>
										<Typography>Do not modify first appointment</Typography>
									</Grid>
								</Grid>
							) : (
								<div></div>
							)}
							{!isNotBooked ? (
								<Grid>
									{secondAppointment && secondAppointment.date && secondAppointment.isCompleted ? (
										<Grid container className={classes.bookingRow} alignItems={"center"} direction={"row"}>
											<Grid>First shot: </Grid>
											<Grid>
												{getAppointmentString(secondAppointment)}
												{/* {`${getDateFormatted(secondAppointment.date)} - ${secondAppointment.hour}:${secondAppointment.minute}`} */}
											</Grid>
											<Grid>Completed</Grid>
										</Grid>
									) : (
										<div></div>
									)}
									{!secondAppointment.isCompleted ? (
										<Grid>
											<Grid container className={classes.bookingRow} alignItems={"center"} direction={"row"}>
												<Grid item>
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<KeyboardDatePicker
															inputVariant="outlined"
															disabled={modifyChecks.second}
															label="Second shot"
															allowKeyboardControl={true}
															variant="inline"
															format="MM-dd-yyyy"
															margin="normal"
															id="date-picker-inline"
															value={secondAppointment.date}
															onChange={handleSecondSlotDateChange}
															KeyboardButtonProps={{
																"aria-label": "change date",
															}}
															autoOk
															minDate={getMinDate(firstAppointment?.date)}
														/>
													</MuiPickersUtilsProvider>
												</Grid>
												<Grid item className={classes.slots}>
													{loadingSecondSlots ? (
														<CircularProgress color="inherit" />
													) : secondSlots.length > 0 ? (
														secondSlots.map(slot => (
															<Box m={0.5} key={`${slot.id}`}>
																<Chip
																	disabled={modifyChecks.second || secondAppointment.newSlot?.id === slot.id}
																	label={`${slot.hour}:${slot.minute}`}
																	onClick={() => handleSecondSlotClick(slot)}
																/>
															</Box>
														))
													) : (
														`No Slots Available on ${secondAppointment.date}`
													)}
												</Grid>
											</Grid>
											<Grid className={classes.modifyCheck}>
												<Checkbox
													disabled={isSecondDisabled}
													checked={modifyChecks.second}
													onChange={event => handleModifyCheck("second", event)}
													inputProps={{"aria-label": "primary checkbox"}}
												></Checkbox>
												<Typography>Do not modify second appointment</Typography>
											</Grid>
										</Grid>
									) : (
										<div></div>
									)}
								</Grid>
							) : (
								<div></div>
							)}
						</Grid>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose()} color="primary">
						Close and discard
					</Button>
					<Button onClick={() => handleCancelAppointments()} color="primary">
						Close all appoinments
					</Button>
					<Button onClick={() => hanldeModifyAppointments()} color="primary" disabled={isSaveDisabled} autoFocus>
						Save and notify
					</Button>
				</DialogActions>
			</div>
		);
	};

	return panel();
}

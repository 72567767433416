import {HEIGHT, WEIGHT} from "./constants.js";

export const getHeightInCM = (height, unit) => {
	if (unit === "ft") return height * 30.48;
	return height;
};

export const getWeightInKG = (weight, unit) => {
	if (unit === "stones") return weight * 6.35029;
	if (unit === "pounds") return weight * 0.453592;
	return weight;
};

export const calculateBMI = (heightCM, weightKG) => {
	let heightM = heightCM / 100;
	return parseFloat(weightKG / (heightM * heightM)).toFixed(2);
};

export const calculateBMIFromProcessedResponse = processedResponse => {
	if (!processedResponse || !processedResponse[HEIGHT] || !processedResponse[WEIGHT]) return;
	let height = getHeightInCM(processedResponse[HEIGHT].numeric_value.Value, processedResponse[HEIGHT].numeric_value.Units);
	let weight = getWeightInKG(processedResponse[WEIGHT].numeric_value.Value, processedResponse[WEIGHT].numeric_value.Units);
	return calculateBMI(height, weight);
};

export const getDateFormatted = value => {
	return value.getFullYear() + "-" + ("0" + (value.getMonth() + 1)).slice(-2) + "-" + ("0" + value.getDate()).slice(-2);
};

export const getDateString = (value, connector = "-") => {
	return (
		("0" + value.getDate()).slice(-2) +
		connector +
		("0" + (value.getMonth() + 1)).slice(-2) +
		connector +
		value
			.getFullYear()
			.toString()
			.slice(-2)
	);
};
